import React, { useState } from "react";
import imagmakeup from "../../Assets/imagmakeup.webp";
import "./index.css";
import logo from "../../Assets/logo.png";
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import { loginSuccess } from '../../service/actions/actions';
import Loader from "../Loader/Loader";

const Register = ({
  isRegisterModalOpen,
  setIsRegisterModalOpen,
  setIsModalOpen,
  loginSuccess,
}) => {
  const [formData, setFormData] = useState({
    email: "",
    phoneNumber: "",
    firstName: "",
    lastName: "",
    // companyName: "",
    // streetAddress: "",
    // city:""
  });
  const [isSignupModalOpen, setSignupModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const toggleSignupModal = () => {
    setIsRegisterModalOpen(false);
    setSignupModalOpen(!isSignupModalOpen);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleLoginClick = () => {
    navigate("/");
    toggleSignupModal();
    setIsModalOpen(true);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
  
    try {
      setLoading(true);
      const response = await fetch(
        "https://apis.joonbeauty.com:550/register",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        }
      );
      const data = await response.json();
      
      if (response.ok && data.success) {
        loginSuccess(data.user);
        // console.log(data.user.firstName)
        navigate("/account");
        setIsRegisterModalOpen(false);
        setLoading(false);
    } else {
        console.error("Registration failed:", data.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };  

  return (
    <div className="register">
      {isRegisterModalOpen && (
        <div className={`register-modal ${isRegisterModalOpen ? "fixed" : "hidden"}`}>
          <div className="fixed top-0 right-0 left-0 z-50 items-center  h-full flex justify-center  bg-opacity-50 bg-black ">
          {loading && <Loader />}
            <div className=" flex justify-center">
              <div className="  relative bg-white rounded-lg shadow ">
                <div className="lg:flex flex-none rounded ">
                  <div className="hidden lg:flex lg:visible">
                    <img src={imagmakeup} alt="" />
                  </div>

                  <div className=" xl:w-[50vh] ">
                    <div className="bg ">
                      <button
                        type="button"
                        className=" bg  float-right text-sm w-8 h-14 ms-auto inline-flex justify-center items-center "
                        onClick={toggleSignupModal}
                      >
                        <svg
                          className="w-3 h-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 14"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                          />
                        </svg>
                        <span className="sr-only ">Close modal</span>
                      </button>
                    </div>
                    <div className="bg flex justify-center">
                      <img src={logo} className="sm-h-auto h-14" alt="" />
                    </div>
                    <div className="flex justify-center">
                      <h1 className="text-[30px] text-center mt-2 pt-4">
                        Register Your Account
                      </h1>
                    </div>

                    <div>
                      <form
                        onSubmit={handleSubmit}
                        className="space-y-4 mt-6 mx-6"
                        action="#"
                      >
                        <div className="flex ">
                          <div>
                            <label
                              htmlFor="firstName"
                              className="block mb-2 text-sm font-medium text-gray-900  "
                            >
                              First Name
                            </label>
                            <input
                              type="text"
                              name="firstName"
                              id="firstName"
                              placeholder="Alex etc"
                              value={formData.firstName}
                              onChange={handleInputChange}
                              required
                              className="border border-black text-gray-900 text-sm rounded-lg block w-[21vh] p-2.5 "
                            />
                          </div>
                          <div>
                            <label
                              htmlFor="lastName"
                              className="block mb-2 text-sm font-medium text-gray-900 ps-4 "
                            >
                              Last Name
                            </label>
                            <input
                              type="text"
                              name="lastName"
                              id="lastName"
                              placeholder="Carry etc"
                              value={formData.lastName}
                              onChange={handleInputChange}
                              required
                              className="border ms-3 border-black text-gray-900 text-sm rounded-lg block w-[21vh] p-2.5"
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="email"
                            className="block mb-2 text-sm font-medium border-black text-gray-900 "
                          >
                            Email
                          </label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            className="border border-black text-gray-900 text-sm rounded-lg block w-full p-2.5"
                            placeholder="name@company.com"
                            required
                          />
                        </div>
                          <div>
                            <label
                              htmlFor="phoneNumber"
                              className="block mb-2 text-sm font-medium text-gray-900 "
                            >
                              Phone Number
                            </label>
                            <input
                              type="text"
                              name="phoneNumber"
                              id="phoneNumber"
                              value={formData.phoneNumber}
                              onChange={handleInputChange}
                              placeholder="000-000-0000"
                              className=" border border-black text-gray-900 text-sm rounded-lg block w-full p-2.5 "
                              required
                            />
                          </div>
                        <button
                          type="submit"
                          className="w-full text-white bg font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                        >
                          Register to your account
                        </button>
                        <div className="text-sm font-medium text-gray-800 pb-3 ">
                          Already have an account?
                          <button
                            className="text-blue-700 hover:underline dark:text-blue-500 ml-1"
                            onClick={handleLoginClick}
                          >
                            Login here
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(null, { loginSuccess })(Register);
