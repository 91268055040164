import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import noimage from "../../Assets/noimage1.png";
import { useLocation } from "react-router-dom";
import "./index.css";

const DetailCart = () => {
  const location = useLocation();
  const order = location.state || {};
  const BASE_IMAGE_URL = "https://apis.joonbeauty.com:550";

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  return (
    <div className="container mx-auto px-4 mt-8 lg:mt-20">
      <h1 className="text-4xl font-bold text-center mb-10 mt-6">
        Order Details
      </h1>
      {order.orderMaster && (
        <div className="mb-10">
          <div className="bg-gray-100 p-4 rounded-lg">
            <p className="font-semibold">
              Order Number: {order.orderMaster.invNum}
            </p>
            <p className="font-semibold">
              Order Date: {formatDate(order.orderMaster.orderDate)}
            </p>
            <p className="font-semibold">
              Grand Total: ${order.orderMaster.grandTotal}
            </p>
            <p className="font-semibold">
              Order Status:{" "}
              {order.orderMaster.odrStatus === 4 ? (
                <span>
                  <span className="text-green-600">Delivered</span>
                </span>
              ) : order.orderMaster.odrStatus === 1 ? (
                <span>
                  <span className="text-blue-600">In Progress</span>
                </span>
              ) : order.orderMaster.odrStatus === 0 ? (
                <span>
                  <span className="text-red-600">Cancelled</span>
                </span>
              ) : (
                "Status Unknown"
              )}
            </p>
          </div>
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 mb-20">
        {order.orderItems.length > 0 ? (
          order.orderItems.map((item, index) => (
            <div
              key={index}
              className="border rounded-lg overflow-hidden shadow-lg flex object-fit sm:justify-start justify-center sm:items-start lg:items-center"
            >
              {item.imageFilename ? (
                <img
                  className="rounded-t-lg w-48 lg:w-48 h-48 lg:h-48 object-cover"
                  src={`${BASE_IMAGE_URL}${item.imageFilename}`}
                  alt=""
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = noimage;
                  }}
                />
              ) : (
                <img
                  className="rounded-t-lg w-48 lg:w-48 h-48 lg:h-48 object-cover"
                  src={noimage}
                  alt="Not found"
                />
              )}
              <div className="p-4">
                <h2 className="text-xl font-semibold">{item.itemNum}</h2>
                <p className="text-gray-600">Price: ${item.price}</p>
                <p className="text-gray-600">Quantity: {item.quantity}</p>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center">
            <p className="mb-4">No order items available.</p>
            <Link
              to="/"
              className="text-blue-500 font-semibold hover:underline"
            >
              Explore the Shop
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  user: state.auth.user,
  orders: state.orders.orders || [],
});

export default connect(mapStateToProps, null)(DetailCart);
