import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchResultsPopup from "./SearchResultsPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FilterModal from "../FilterModal/FilterModal";

const SearchBar = ({
  searchQuery,
  handleChange,
  handleSearch,
  result,
  showPopup,
  setLoading,
  setShowPopup,
  setSearchQuery,
  addToCart,
  selectedBrand,
  setSelectedBrand,
  selectedCategory,
  setSelectedCategory
}) => {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
      navigate("/search");
      setShowPopup(false);
    }
  };

  const handleOnSearch = () => {
    handleSearch(searchQuery, selectedBrand);
    navigate("/search");
    setLoading(true);
    setShowPopup(false);
  };

  const handleInputChange = (event) => {
    handleChange(event);
  };

  const fetchBrands = async () => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      };

      const response = await fetch(
        "https://apis.joonbeauty.com:550/getItemBrands",
        requestOptions
      );
      const data = await response.json();
      setBrands(data.data);
      // console.log("Fetched brands:", data.data);
    } catch (error) {
      console.error("Error fetching brands:", error);
      toast.error("Failed to fetch brands. Please try again later.", {
        position: "top-center",
      });
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch("https://apis.joonbeauty.com:550/ItemCategories", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      });
      const data = await response.json();
      setCategories(data.data);
      // console.log("Fetched categories:", data.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
      toast.error("Failed to fetch categories. Please try again later.", {
        position: "top-center",
      });
    }
  };

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
    console.log("BRanrnr: ", selectedBrand)
    if (!isModalOpen) {
      fetchBrands();
      fetchCategories();
    }
  };

  const handleBrandSelect = (brand) => {
    setSelectedBrand(brand);
  };
  const handleCategorySelect = (categories) => {
    setSelectedCategory(categories);
  };

  return (
    <div className="relative flex justify-center items-center lg:mt-0">
      <div className="relative flex items-center w-[360px] md:w-[95vh] border border-gray-300 rounded-lg bg-white">
        <button
          className="text-gray-500 font-semibold bg-gray-100 hover:bg-gray-200 p-2 rounded-l-lg transition-all duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-gray-300"
          onClick={toggleModal}
        >
          <FontAwesomeIcon icon={faFilter} />
        </button>
        <div className="h-6 w-px bg-gray-300 mx-2"></div>
        <input
          ref={inputRef}
          type="text"
          id="search-navbar"
          value={searchQuery}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          className="block w-full p-2 text-sm text-gray-900 outline-none bg-white dark:bg-white-700 dark:placeholder-gray-900 dark:text-gray-700 text-xs"
          placeholder="Search..."
        />
        {/* <button
          onClick={handleOnSearch}
          disabled={!searchQuery}
          className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-auto bg-red-100 rounded-r-lg px-3 my-2 mx-4 border"
        >
          <svg
            className="w-4 h-4 text-red-500 cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
            />
          </svg>
        </button> */}
      </div>
      {showPopup && (
        <SearchResultsPopup
          results={result}
          inputRef={inputRef}
          handleSearch={handleOnSearch}
          setSearchQuery={setSearchQuery}
          handleAddToCart={addToCart}
          setShowPopup={setShowPopup}
          brands={brands}
          selectedBrand={selectedBrand}
        />
      )}
      <FilterModal
        isOpen={isModalOpen}
        onClose={toggleModal}
        brands={brands}
        categories={categories}
        handleBrandSelect={handleBrandSelect}
        handleCategorySelect={handleCategorySelect}
      />
    </div>
  );
};

export default SearchBar;