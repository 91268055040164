import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { connect } from "react-redux";
import Loader from "../Loader/Loader";
import {
  requestAddress,
  receiveAddressSuccess,
  receiveAddressFailure,
  updateDeliveryAddress,
} from "../../service/actions/actions";
import '../PaymentModal/PaymentModal.css'

const AddressModal = ({
  isOpen,
  onClose,
  requestAddress,
  receiveAddressSuccess,
  receiveAddressFailure,
  updateDeliveryAddress,
  customerNumber,
  deliveryAddress,
}) => {
  const [addressDetails, setAddressDetails] = useState({
    streetAddress: deliveryAddress?.streetAddress || "",
    city: deliveryAddress?.city || "",
    custNum: customerNumber,
  });  
  const [loading, setLoading] = useState(false);
  const [formComplete, setFormComplete] = useState(false);

  useEffect(() => {
    const isFormComplete =
      addressDetails.streetAddress && addressDetails.city;

    setFormComplete(isFormComplete);
  }, [addressDetails]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddressDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      setLoading(true);
  
      const response = await fetch(
        "https://apis.joonbeauty.com:550/updateDeliveryAddress",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            custNum: customerNumber,
            streetAddress: addressDetails.streetAddress,
            city: addressDetails.city,
          }),
        }
      );
  
      if (!response.ok) {
        throw new Error("Failed to update delivery address");
      }
  
      const updatedAddress = await response.json();
  
      setAddressDetails({
        ...addressDetails,
        streetAddress: updatedAddress.streetAddress,
        city: updatedAddress.city,
      });
  
      receiveAddressSuccess(updatedAddress);
      setTimeout(() => {
        const updatedAddress = {
          streetAddress: addressDetails.streetAddress,
          city: addressDetails.city,
        };
        updateDeliveryAddress(updatedAddress);

        onClose();
      }, 1000);
    } catch (error) {
      console.error("Error updating delivery address:", error.message);
      receiveAddressFailure(error.message);
    } finally {
      setLoading(false);
    }
  };   
  return (
    <div>
      {isOpen && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          <div className="bg-white w-96 rounded-lg overflow-hidden relative">
            <div className="p-6">
              {loading && <Loader />}
              <h2 className="text-xl font-semibold mb-4 text-center">
                Edit Delivery Address
              </h2>
              <button
                className="absolute top-0 right-0 m-3 text-gray-500 hover:text-gray-700"
                onClick={onClose}
              >
                <FaTimes size={20} />
              </button>
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Street Address
                  </label>
                  <input
                    className="block w-full border bgclr rounded py-2 px-3 focus:outline-none focus:border-blue-500"
                    type="text"
                    name="streetAddress"
                    value={addressDetails.streetAddress}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-700">
                    City
                  </label>
                  <input
                    className="block w-full border bgclr rounded py-2 px-3 focus:outline-none focus:border-blue-500"
                    type="text"
                    name="city"
                    value={addressDetails.city}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mt-6">
                  <button
                    type="submit"
                    className={`bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 w-full ${
                      !formComplete && "opacity-50 cursor-not-allowed"
                    }`}
                    disabled={!formComplete}
                  >
                    Save Changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  deliveryAddress: state.address.deliveryAddress,
});

const mapDispatchToProps = (dispatch) => ({
  requestAddress: () => dispatch(requestAddress()),
  receiveAddressSuccess: (address) =>
    dispatch(receiveAddressSuccess(address)),
  receiveAddressFailure: (error) =>
    dispatch(receiveAddressFailure(error)),
  updateDeliveryAddress: (address) =>
    dispatch(updateDeliveryAddress(address)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressModal);