import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import PaymentModal from "../PaymentModal/PaymentModal";
import OrderDetailsModal from "../OrderDetailsModal/OrderDetailsModal";
import {
  fetchOrdersRequest,
  fetchOrdersSuccess,
  fetchOrdersFailure,
  fetchOrders,
} from "../../service/actions/actions";
import DeliveryAddressModal from "../AddressModal/AddressModal";
import {
  updateBillingAddress,
  updateDeliveryAddress,
  logout,
} from "../../service/actions/actions";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from "react-toastify";
import Header from "../Header/Header";
import { ThreeDots } from 'react-loader-spinner';

const Account = ({
  isLoggedIn,
  billingAddress,
  deliveryAddress,
  user,
  orders,
  fetchOrders,
  updateBillingAddress,
  updateDeliveryAddress,
  logout,
  fetchOrdersRequest,
  fetchOrdersSuccess,
  fetchOrdersFailure,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [isDeliveryAddressModalOpen, setIsDeliveryAddressModalOpen] =
    useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("jwtToken");
  // const [welcomeMessage, setWelcomeMessage] = useState("");

  const isTokenExpired = (token) => {
    try {
      const { exp } = JSON.parse(atob(token.split('.')[1]));
      return exp < Date.now() / 1000;
    } catch (e) {
      console.error("Failed to parse JWT token", e);
      return true;
    }
  };

  useEffect(() => {
    const fetchBillingDetails = async () => {
      try {

        if (!isLoggedIn || !user.customerNumber) {
          return;
        }

        if (isTokenExpired(token)) {
          console.error("JWT token has expired");
          logout();
          return;
        }

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(user.customerNumber),
          redirect: "follow",
        };

        fetch("https://apis.joonbeauty.com:550/getCardDetails", requestOptions)
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((result) => {
            // console.log("CardDetails", result);
            updateBillingAddress(result.data[0]);
          })
          .catch((error) => {
            console.error("Error fetching billing details:", error);
          });
      } catch (error) {
        console.error("Error fetching billing details:", error);
      }
    };

    if (isLoggedIn && user.customerNumber) {
      fetchBillingDetails();
    }
  }, [isLoggedIn, user.customerNumber, updateBillingAddress, logout, token]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        if (isLoggedIn && user.customerNumber) {
          fetchOrdersRequest();
          setLoading(true);
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append(
            "Authorization",
            `Bearer ${token}`,
          );

          const raw = JSON.stringify(user.customerNumber);

          const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };

          const response = await fetch(
            "https://apis.joonbeauty.com:550/OrdersByCustNum",
            requestOptions
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const data = await response.json();
          // console.log("Orders details:", data);
          fetchOrdersSuccess(data);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
        fetchOrdersFailure(error.message);
      }
    };

    fetchOrders();
  }, [
    isLoggedIn,
    user.customerNumber,
    fetchOrdersRequest,
    fetchOrdersSuccess,
    fetchOrdersFailure,
    token
  ]);

  useEffect(() => {
    const fetchDeliveryAddress = async () => {
      try {
        if (isLoggedIn && user.customerNumber) {
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(user.customerNumber),
            redirect: "follow",
          };

          // console.log("CustNum: ", user.customerNumber);

          const response = await fetch(
            "https://apis.joonbeauty.com:550/getCustomerInfo",
            requestOptions
          );
          const data = await response.json();
          if (response.ok) {
            updateDeliveryAddress(data.data);
          } else {
            throw new Error("Network response was not ok");
          }
        }
      } catch (error) {
        console.error("Error fetching delivery address:", error);
      }
    };

    fetchDeliveryAddress();
  }, [isLoggedIn, user.customerNumber, updateDeliveryAddress, token]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    document.body.style.overflow = isModalOpen ? "" : "hidden";
  };

  const SeeAllClick = (order) => {
    navigate("/DetailCart", { state: order });
    setSelectedOrder(order);
    // console.log("New User in modal: ", user)
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const maskCardNumber = (cardNumber) => {
    const visibleDigits = 4;
    if (!cardNumber || cardNumber.length <= visibleDigits) {
      return cardNumber;
    }
    const masked =
      "*".repeat(cardNumber.length - visibleDigits) +
      cardNumber.slice(-visibleDigits);
    return masked;
  };

  const toggleDeliveryAddressModal = () => {
    setIsDeliveryAddressModalOpen((prevIsOpen) => !prevIsOpen);
  };

  // console.log("Email: ", user.email);

  useEffect(() => {
    const welcomeFlag = localStorage.getItem("showWelcomeToast");
    if (isLoggedIn && user.firstName && welcomeFlag === "true") {
      toast.success(`Welcome, ${user.firstName}!`);
      localStorage.setItem("showWelcomeToast", "false");
    }
  }, [isLoggedIn, user.firstName]);

  return (
    <>
    <Header/>
    <div className="bg-gray-100 lg:mt-16 h-full">
    <ToastContainer />
      <div className="container mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="items-center justify-between mb-4 flex">
            <h1 className="text-3xl font-semibold mb-6 mr-4 text-center">
              Manage My Account
            </h1>
            <FontAwesomeIcon
              className="text-orange-500 text-2xl cursor-pointer md:hidden mb-4 "
              onClick={handleLogout}
              icon={faSignOutAlt}
            />
          </div>
          <div className="flex flex-wrap -mx-4">
            <div className="w-full md:w-1/1 lg:w-1/3 px-4 mb-8">
              <div className="bg-white shadow-md rounded-lg p-6 md:h-50 h-full">
                <h2 className="text-xl font-semibold mb-4">Personal Profile</h2>
                <div className="text-sm mb-4">
                  <div className="font-semibold mb-1">
                    {isLoggedIn ? user.firstName : "User Name"}
                  </div>
                  <div>Email: {user.email}</div>
                  <div>Phone Number: {user.phoneNumber}</div>
                  <div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/1 lg:w-2/3 px-4 mb-8">
              <div className="bg-white shadow-md rounded-lg p-7 h-full">
                <h2 className="text-xl font-semibold mb-4">Address Book</h2>
                <div className="md:flex mb-4">
                  <div className="w-full md:w-1/2 pr-4 mb-3">
                    <div className="flex items-center">
                      <div className="font-semibold mb-1 lg:mr-3 ">
                        DEFAULT DELIVERY ADDRESS
                      </div>
                      {/* <span
                        className="text-sm text-blue-500 font-semibold cursor-pointer ml-3"
                        onClick={toggleDeliveryAddressModal}
                      >
                        EDIT
                      </span> */}
                    </div>
                    <div className="text-sm">
                      {deliveryAddress ? (
                        <div>
                          <div>{deliveryAddress.streetAddress}</div>
                          <div>{deliveryAddress.city}</div>
                        </div>
                      ) : (
                        <div>No delivery address available</div>
                      )}
                    </div>
                  </div>
                  <div className="w-full md:w-1/2">
                    <div className="border-l"></div>
                    <div className="flex items-center ">
                      <div className="font-semibold mb-1 lg:mr-3">
                        DEFAULT BILLING ADDRESS
                      </div>
                      {/* <span
                        className="text-sm text-blue-500 font-semibold cursor-pointer ml-3"
                        onClick={toggleModal}
                      >
                        EDIT
                      </span> */}
                    </div>
                    <div className="text-sm">
                      {billingAddress ? (
                        <div>
                          <div>
                            Card Holder Name: {billingAddress.cardHolderName}
                          </div>
                          <div>
                            Card Number:{" "}
                            {maskCardNumber(billingAddress.cardNumber)}
                          </div>
                          <div>
                            Exp Date: {billingAddress.expiryMonth}/
                            {billingAddress.expiryYear}
                          </div>
                        </div>
                      ) : (
                        <div>No billing details available</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {loading ? (
                <div className="flex justify-center items-center h-32">
                  <ThreeDots
                    type="ThreeDots"
                    color="#f65e00"
                    height={50}
                    width={50}
                  />
                </div>
              ) : orders.length > 0 ? (
            <div className="bg-white shadow-md rounded-lg p-6">
              <div className="justify-between items-center">
                <h2 className="text-3xl font-semibold mb-3 flex justify-center text-center">
                  Recent Orders
                </h2>
              </div>
              <div className="overflow-x-auto">
                <table className="table-auto w-full">
                  <thead className="lg:ms-[58vh] justify-center">
                    <tr className="border-b ">
                      <th className="text-start text-sm md:text-base lg:text-lg lg:ps-4">
                        Order Status
                      </th>
                      <th className="text-start text-sm md:text-base lg:text-lg lg:ps-4 ps-4">
                        Placed On
                      </th>
                      <th className="text-start text-sm md:text-base lg:text-lg lg:ps-4 ps-4">
                        Total
                      </th>
                      <th className="text-start text-sm md:text-base lg:text-lg ps-4">
                        Show Items
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {orders.map((order, index) => {
                      const orderDate = new Date(order.orderMaster.orderDate);
                      const formattedDate = orderDate.toLocaleDateString();
                      const formattedTime = orderDate.toLocaleTimeString();
                      // console.log("Order:", orders);
                      return (
                        <tr key={index} className="border-b">
                          <td className="px-4 py-2 text-start">
                            {order.orderMaster.odrStatus === 4 ? (
                              <span>
                                Order{" "}
                                <span className="text-green-600">
                                  Delivered
                                </span>
                              </span>
                            ) : order.orderMaster.odrStatus === 1 ? (
                              <span>
                                Order{" "}
                                <span className="text-blue-600">
                                  In Progress
                                </span>
                              </span>
                            ) : order.orderMaster.odrStatus === 0 ? (
                              <span>
                                Order{" "}
                                <span className="text-red-600">Cancelled</span>
                              </span>
                            ) : (
                              "Status Unknown"
                            )}
                          </td>
                          <td className="px-4 py-2 text-start">
                            {formattedDate} {formattedTime}
                          </td>
                          <td className="px-4 py-2 text-start">
                            ${order.orderMaster.grandTotal}
                          </td>
                          <td className="px-4 py-2 text-start">
                            <button
                              className="text-blue-500"
                              onClick={() => SeeAllClick(order)}
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div>
              <h1 className="flex justify-center text-lg">
                No Recent Product Order
              </h1>
            </div>
          )}
        </div>
      </div>
      <PaymentModal
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        customerNumber={user.customerNumber}
        billingAddress={billingAddress}
      />
      <OrderDetailsModal
        isOrderModalOpen={isOrderModalOpen}
        toggleModal={() => setIsOrderModalOpen(false)}
        order={selectedOrder}
      />
      <DeliveryAddressModal
        isOpen={isDeliveryAddressModalOpen}
        onClose={toggleDeliveryAddressModal}
        deliveryAddress={deliveryAddress}
        user={user}
        customerNumber={user.customerNumber}
      />
    </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  billingAddress: state.payment.billingAddress,
  deliveryAddress: state.address.deliveryAddress,
  user: state.auth.user || { customerNumber: null },
  orders: state.orders.orders || [],
});

const mapDispatchToProps = {
  updateBillingAddress,
  updateDeliveryAddress,
  logout,
  fetchOrders,
  fetchOrdersRequest,
  fetchOrdersSuccess,
  fetchOrdersFailure,
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);