import React from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { loginSuccess, loginFailure } from "../../service/actions/actions";
import imagmakeup from "../../Assets/imagmakeup.webp";
// import { useNavigate } from "react-router-dom";
import logo from "../../Assets/logo.png";
import "../Modal/Modal.css";

const ConfirmationModal = ({
  toggleConfirmationModal,
  toggleLoginModal,
  fromCartPage,
  data,
  loginSuccess,
  updateBillingAddress,
  storeUserData,
  user,
}) => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const handleConfirm = () => {
    if (data.success) {
      localStorage.setItem("jwtToken", data.token);
      // console.log("token: ", data.token)
      localStorage.setItem("user", JSON.stringify({ data }));
      loginSuccess(data);
      dispatch(storeUserData(data.customer));
      updateBillingAddress(data.billingAddress);
      // console.log("New User: ", user)
      // toggleLoginModal(false);
      toggleConfirmationModal(false);
      // console.log("New User: ", user)
      // console.log("user data: ", data)
      // if (!fromCartPage) {
      //   navigate("/account");
      // }
    }
  };

  const handleDisagree = () => {
    toggleConfirmationModal();
    toggleLoginModal();
  };

  return (
    <div className="fixed top-0 right-6 lg:right-0 left-0 z-60 flex items-center h-screen bg-opacity-50 bg-black">
      <div className="relative m-auto">
        <div className="relative bg-white rounded-lg shadow">
          <div className="lg:flex flex-none rounded">
            <div className="hidden lg:flex lg:visible">
              <img src={imagmakeup} alt="" />
            </div>
            <div className="xl:w-[50vh] width-two">
              <div className="bg">
                <button
                  onClick={toggleConfirmationModal}
                  className="bg float-right text-sm w-8 h-10 md:h-16 ms-auto inline-flex justify-center items-center shadow-md"
                  type="button"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="bg flex justify-center shadow-md">
                <img src={logo} className="h-10 w-auto md:h-16 p-2" alt="" />
              </div>
              <div className="content">
                <div className="flex justify-center">
                  <h1 className="text-center mt-4 sm:pt-9 pt-3 text-modal text-2xl font-bold">
                    Confirm Login
                  </h1>
                </div>
                <ul className="bg-white p-6 rounded-lg shadow-lg mx-5 mt-6 space-y-4">
                  <li className="flex items-center">
                    <span className="font-semibold text-gray-700 w-40">
                      Customer number:
                    </span>
                    <span className="text-gray-900">
                      {data.customer.customerNumber}
                    </span>
                  </li>
                  <li className="flex items-center">
                    <span className="font-semibold text-gray-700 w-40">
                      Shipping address:
                    </span>
                    <span className="text-gray-900">
                      {data.customer.streetAddress}
                    </span>
                  </li>
                  <li className="flex items-center">
                    <span className="font-semibold text-gray-700 w-40">
                      Contact number:
                    </span>
                    <span className="text-gray-900">
                      {data.customer.phoneNumber}
                    </span>
                  </li>
                </ul>

                <div className="sm:p-4 md:p-5 p-2 mt-3 lg:mt-8">
                  <p className="text-center text-lg">
                    Are you sure you want to log in?
                  </p>
                  <div className="flex justify-center mt-6 space-x-4">
                    <button
                      onClick={handleConfirm}
                      className="w-1/3 text-white bg-gray-600 hover:bg-black border border-transparent font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                      Confirm
                    </button>
                    <button
                      onClick={handleDisagree}
                      className="w-1/3 text-black bg-gray-200 hover:bg-gray-300 border border-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user || { customerNumber: null },
});

const mapDispatchToProps = (dispatch) => ({
  loginSuccess: (user) => dispatch(loginSuccess(user)),
  loginFailure: (error) => dispatch(loginFailure(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);
