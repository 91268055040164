import React, { useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import { connect } from "react-redux";
import CheckboxSection from "./CheckboxSection";
import Card from "./Card";
import ButtonsSection from "./ButtonSection";
import ProductSection from "./ProductSection";
import {
  addToCart,
  fetchProductsFailure,
  fetchProductsSuccess,
} from "../../service/actions/actions";
import Header from "../Header/Header";
import { ToastContainer } from "react-toastify";
import CategoryBar from "../CategoryBar/CategoryBar";
import BannerSlider from "../BannerSlider/BannerSlider";
import "./styles.css";

const Catalog = ({ fetchProductsSuccess, fetchProductsFailure, addToCart }) => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState("");
  const [nextPage, setNextPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [checkCategory, setCheckCategory] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [brands, setBrands] = useState([]);
  const [bestSeller, setBestSeller] = useState("");

  const fetchProducts = async (reset = false) => {
    setLoading(true);
    try {
      // const isItemNum = /^[A-Z0-9]+$/.test(searchQuery);
      const response = await fetch(
        "https://apis.joonbeauty.com:550/getAllBrands",
        // "https://localhost:44366/getAllBrands",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            page: reset ? 1 : nextPage,
            perPage: 15,
            category: selectedCategories || selectedCategory || null,
            itemBrand: selectedBrand || null,
            // itemNum: isItemNum ? searchQuery : "",
            itemName: searchQuery,
            bestSeller: bestSeller || null,
          }),
        }
      );

      const data = await response.json();
      console.log("SearchData: ", data);

      if (Array.isArray(data.data)) {
      if (reset) {
        setProducts(data.data);
        setNextPage(2);
      } else {
        setProducts((prevProducts) => [...prevProducts, ...data.data]);
        setNextPage((prevPage) => prevPage + 1);
      }

      if (data.data.length === 0) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }

      fetchProductsSuccess(data.data);
    } else {
      console.error("Unexpected data format:", data);
      fetchProductsFailure(new Error("Unexpected data format"));
    }
    } catch (error) {
      console.error("Fetch error:", error);
      fetchProductsFailure(error);
    } finally {
      setIsLoading(false);
      setLoading(false);
    }
  };
  

  useEffect(() => {
    fetchProducts(true);
  }, [
    selectedCategories,
    selectedCategory,
    searchQuery,
    selectedBrand,
    bestSeller,
  ]);

  const debouncedSearch = useCallback(
    debounce((query) => {
      setSearchQuery(query);
    }, 300),
    []
  );

  const handleChange = (event) => {
    const newQuery = event.target.value;
    debouncedSearch(newQuery);
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch(
        "https://apis.joonbeauty.com:550/ItemCategories",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        }
      );
      const data = await response.json();
      setCategories(data.data);
    } catch (error) {
      console.error("Fetch categories error:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchBrands = async () => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      };

      const response = await fetch(
        "https://apis.joonbeauty.com:550/getItemBrands",
        requestOptions
      );
      const data = await response.json();
      setBrands(data.data);
      console.log("Fetched brands 1:", data.data);
    } catch (error) {
      console.error("Error fetching brands:", error);
      // toast.error("Failed to fetch brands. Please try again later.", {
      //   position: "top-center",
      // });
    }
  };

  const handleCategoryChange = (categoryName) => {
    setSelectedCategories(categoryName);
    // setSelectedCategory("");
    setSelectedBrand("");
    setNextPage(1);
    setProducts([]);
  };

  const getCatalogTitle = () => {
    if (bestSeller) {
      return `Best Seller Products`;
    }
    return selectedCategory
      ? `${selectedCategory} Products Wholesale`
      : "New Products Wholesale";
  };  

  return (
    <>
      <div className="lg:mb-5 mb-1">
        <Header />
      </div>
      <div className="mx-auto lg:py-6">
        <CategoryBar
          bestSeller={bestSeller}
          products={products}
          setBestSeller={setBestSeller}
          fetchProducts={fetchProducts}
          setSelectedCategory={setSelectedCategory}
          setCheckCategory={setCheckCategory}
          setNextPage={setNextPage}
          onCategoryChange={handleCategoryChange}
          setProducts={setProducts}
        />
      </div>
      <div className="mb-7">
        <BannerSlider />
        </div>
      <div className="container mx-auto lg:px-4 lg:py-1">
        <ToastContainer />
        <h1 className="font-sans text-3xl text-center mb-1 font-sans w1">
        {getCatalogTitle()}
        </h1>
        <h4 className="text-center mb-3 text-gray-500 lg:mx-auto font-normal text-gray-dark w4">
          Select, Filter and Place Your Order Below
        </h4>
        {/* Checkbox Section */}
        <CheckboxSection
          categories={categories}
          onCategoryChange={handleCategoryChange}
          loading={isLoading}
          checkCategory={checkCategory}
          setCheckCategory={setCheckCategory}
          selectedBrand={selectedBrand}
        />
        {/* Card Section */}
        <Card
          addToCart={addToCart}
          selectedBrand={selectedBrand}
          setSelectedBrand={setSelectedBrand}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          checkCategory={checkCategory}
          setCheckCategory={setCheckCategory}
          onCategoryChange={handleCategoryChange}
          handleChange={handleChange}
          fetchProducts={fetchProducts}
          setSearchQuery={setSearchQuery}
          fetchBrands={fetchBrands}
          brands={brands}
        />
        {/* Buttons Section */}
        <ButtonsSection
          categories={selectedCategories}
          setSearchQuery={setSearchQuery}
          setSelectedBrand={setSelectedBrand}
          onCategoryChange={handleCategoryChange}
          fetchProducts={fetchProducts}
          selectedBrand={selectedBrand}
          setBestSeller={setBestSeller}
          bestSeller={bestSeller}
        />
        {/* Products Section */}
        <ProductSection
          products={products}
          loading={loading}
          setLoading={setLoading}
          categories={selectedCategories}
          fetchProducts={fetchProducts}
          setProducts={setProducts}
          hasMore={hasMore}
        />
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addToCartHandler: (data) => dispatch(addToCart(data)),
  fetchProductsSuccess: (products) => dispatch(fetchProductsSuccess(products)),
  fetchProductsFailure: (error) => dispatch(fetchProductsFailure(error)),
});

export default connect(null, mapDispatchToProps)(Catalog);
