import React, { useState } from "react";
import { connect } from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import { addToCart } from "../../service/actions/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
// import Carousal from "../../Components/Slider/Carousal";
import Header from "../Header/Header";

const ProductSearch = ({ searchQuery, handleChange, handleSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [product, setProduct] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const cartItems = useSelector((state) => state.cardItems.cartItems || []);

  const BASE_IMAGE_URL = "https://apis.joonbeauty.com:550";

  const handleViewCartClick = () => {
    navigate("/cart");
    // console.log("Quick Shop button clicked!");
  };

  const handleSearchbyCode = (event) => {
    setSearchTerm(event.target.value);
  };

  const searchProduct = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://apis.joonbeauty.com:550/getItemByItemNum",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ itemNum: searchTerm }),
        }
      );

      const result = await response.json();
      if (result.success) {
        setProduct(result.data[0]);
        setError("");
        setShowError(false);
        window.scrollTo(0, 0);
      } else {
        setProduct(null);
        setError(result.message);
        setShowError(true);
      }
    } catch (err) {
      setProduct(null);
      setError("An error occurred while searching for the product.");
      setShowError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleAddToCart = () => {
    if (!product) return;

    const existingItem = cartItems.find(
      (cartItem) => cartItem.id === product.itemNum
    );
    if (existingItem) {
      toast.error("Item already exists in cart", {
        position: "top-center",
      });
    } else {
      dispatch(
        addToCart(
          {
            price: product.price,
            name: product.itemName,
            id: product.itemNum,
            image: product.imageFilename,
          },
          quantity
        )
      );
    }
  };

  const handleQuantityChange = (event) => {
    const newQuantity = parseInt(event.target.value);
    if (newQuantity < 1) return;
    setQuantity(newQuantity);
  };

  return (
    <>
      {/* <Carousal
        bgColor="#a0aec0"
        padding="md:pt-0"
        searchQuery={searchQuery}
        handleChange={handleChange}
        handleSearch={handleSearch}
      /> */}
      <Header/>
      <div className="min-h-screen bg-gray-200 flex items-center justify-center p-4">
        <ToastContainer />
        <div className="bg-white shadow-2xl rounded-lg p-8 max-w-lg w-full lg:mt-20 mb-12">
          <h1 className="text-4xl font-semibold text-center mb-8 text-gray-800">
            Product Search
          </h1>
          <div className="mb-6">
            <input
              type="text"
              placeholder="Enter product code"
              value={searchTerm}
              onChange={handleSearchbyCode}
              className="shadow-md appearance-none border rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <button
            onClick={searchProduct}
            className={`bg-[#f65e00] text-white font-bold py-2 px-4 rounded-lg w-full hover:bg-[#e05500] transition duration-300 ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={loading}
          >
            {loading ? "Searching..." : "Search"}
          </button>
          {showError && (
            <div className="mt-6">
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                {/* <strong className="font-bold">Error:</strong> */}
                <span className="block sm:inline">{error}</span>
                <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={() => setShowError(false)}>
                  <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <title>Close</title>
                    <path d="M14.348 14.849a1 1 0 01-1.414 0L10 11.414l-2.933 2.935a1 1 0 11-1.414-1.414l2.933-2.935-2.935-2.935a1 1 0 111.414-1.414L10 8.586l2.934-2.935a1 1 0 011.414 1.414l-2.935 2.935 2.935 2.935a1 1 0 010 1.414z"/>
                  </svg>
                </span>
              </div>
            </div>
          )}
          {product && (
            <div className="mt-6 bg-gray-50 p-6 rounded-lg shadow-inner transition duration-300 ease-in-out transform flex">
              <img
                src={`${BASE_IMAGE_URL}${product.imageFilename}`}
                alt={product.itemName}
                className="max-w-full h-auto rounded-lg shadow-lg mr-4"
                style={{ width: "150px", height: "150px" }}
              />
              <div>
                <h2 className="text-2xl font-semibold text-gray-800">
                  {product.itemName}
                </h2>
                <p className="text-red-900">Price: ${product.price}</p>
                <p className="text-gray-900">Code: {product.itemNum}</p>
              </div>
            </div>
          )}
          {product && (
            <div className="mt-4">
              <div className="mt-4 flex items-center">
                <label className="mr-2 font-semibold">Quantity:</label>
                <input
                  type="number"
                  min="1"
                  value={quantity}
                  onChange={handleQuantityChange}
                  className="shadow appearance-none border rounded w-100 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <button
                type="button"
                className="mt-4 bg-[#f65e00] hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg w-full"
                onClick={handleAddToCart}
              >
                Add to Cart
              </button>
              <button
                type="button"
                className="mt-4 bg-[#f65e00] hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg w-full"
                onClick={handleViewCartClick}
              >
                View Cart
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  cartItems: state.cardItems.cartItems || [],
});

const mapDispatchToProps = (dispatch) => ({
  addToCart: (data, quantity) => dispatch(addToCart(data, quantity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductSearch);