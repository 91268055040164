import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import banner2 from '../../Assets/jcop-banner-2.jpg';
import banner3 from '../../Assets/jcop-banner-3.jpg';
import banner4 from '../../Assets/jcop-banner-4.jpg';
import banner5 from '../../Assets/jcop-banner-5.jpg';
import banner6 from '../../Assets/jcop-banner-6.jpg';
import './BannerSlider.css'

const BannerSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const images = [banner2, banner3, banner4, banner5, banner6];

  return (
    <div className="banner-slider">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="slider-item">
            <img
              className="object-contain w-full h-full"
              src={image}
              alt={`Banner ${index + 1}`}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default BannerSlider;