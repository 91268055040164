import React from "react";

const OrderDetailsModal = ({ isOrderModalOpen, toggleModal, order }) => {
  return (
    <div>
      {isOrderModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold">Order Details</h3>
              <button className="text-gray-600 hover:text-gray-800 focus:outline-none" onClick={toggleModal}>X</button>
            </div>
            <div className="modal-body">
              {order && (
                <div>
                  <p><strong>Order ID:</strong> {order.orderId}</p>
                  <p><strong>Status:</strong> {order.odrStatus === 4 ? 'Delivered' : (order.odrStatus === 1 ? 'In Progress' : 'Cancelled')}</p>
                  <p><strong>Placed On:</strong> {order.orderDate}</p>
                  <p><strong>Total:</strong> ${order.grandTotal}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderDetailsModal;