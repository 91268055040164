import React from "react";
import { connect } from "react-redux";
import { addToCart } from "../../service/actions/actions";

const SearchResultsPopup = ({
  results,
  inputRef,
  addToCartHandler,
  setSearchQuery,
  setShowPopup,
  brands,
  selectedBrand
}) => {
  const inputRect = inputRef.current
    ? inputRef.current.getBoundingClientRect()
    : null;

  const handleOnItemClick = (item) => {
    addToCartHandler({
      price: item.price,
      name: item.itemName,
      id: item.itemNum,
      brand:item.itemBrand,
      image: item.imageFilename,
      quantity:1
    });
    setShowPopup(false);
    setSearchQuery("");
  };

  const isMobile = window.innerWidth < 768;
  const isDesktop = window.innerWidth > 1326;
  const isMediumScreen = window.innerWidth > 768 && window.innerWidth <= 1326;
  const wPosition = isMediumScreen
    ? "200px"
    : inputRect
    ? inputRect.width + "px"
    : "300px";
    const topPosition =
    window.location.pathname === "/cart"
      ? "39px"
      : isDesktop
      ? inputRect
        ? inputRect.bottom + window.scrollY - 26 + "px"
        : "0px"
      : inputRect
      ? inputRect.bottom + window.scrollY + "px"
      : "95px";

  const marginLeft =
    isMobile && window.location.pathname !== "/cart" ? "36px" : "0";

  return (
    <div
      className={`absolute left-0 bg-white dark:bg-white-700 border border-gray-300 rounded-lg shadow-md p-4 z-50`}
      style={{
        top: topPosition,
        width: wPosition,
        marginLeft: marginLeft,
      }}
    >
      <ul className="text-left">
        {results.slice(0, 5).map((item, index) => (
          <li
            key={index}
            className="py-2 px-4 mb-2 border rounded-lg cursor-pointer hover:bg-gray-200"
            onClick={() => handleOnItemClick(item)}
          >
            <div className="flex justify-between items-center">
              <span className="truncate w-36 text-xs">{item.itemName} </span>
              <div>
              <span className="text-gray-500 text-xs">({item.itemNum})</span>
              {selectedBrand && (
                <span className="text-gray-500 ml-2 text-xs">({selectedBrand})</span>
              )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addToCartHandler: (data) => dispatch(addToCart(data)),
});

export default connect(null, mapDispatchToProps)(SearchResultsPopup);
