import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { addToCart } from "../../service/actions/actions";
import {
  faStar,
  faHeart,
  faSmile,
  faEye,
  faFaceGrin,
  faTags,
} from "@fortawesome/free-solid-svg-icons";
import useFetchBrandData from "../data/data";
import "./CategoryBar.css";

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const CategoryBar = ({
  setBestSeller,
  fetchProducts,
  setSelectedCategory,
  setCheckCategory,
  setNextPage,
  onCategoryChange,
  addToCartHandler,
}) => {
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [fetchCalled, setFetchCalled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hoveredProducts, setHoveredProducts] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  const brandData = useFetchBrandData();

  useEffect(() => {
    if (brandData && Object.keys(brandData).length > 0) {
      setLoading(false);
    }
  }, [brandData]);

  const newProducts = { id: 1, name: "NEW", icon: faStar };
  const bestSellers = { id: 2, name: "BESTSELLERS", icon: faHeart };

  const otherCategories = [
    { id: 3, name: "LIP", icon: faSmile },
    { id: 4, name: "EYE", icon: faEye },
    { id: 5, name: "FACE", icon: faFaceGrin },
    { id: 6, name: "MAKEUP", icon: faTags },
  ];

  const categoryMap = {
    [bestSellers.id]: { key: "bestSeller", value: "bestSeller" },
    [newProducts.id]: { key: "newProducts" },
    [otherCategories[0].id]: { key: "category", value: "LIP" },
    [otherCategories[1].id]: { key: "category", value: "EYES" },
    [otherCategories[2].id]: { key: "category", value: "FACE" },
    [otherCategories[3].id]: { key: "category", value: "MAKEUP" },
  };

  const debouncedFetchProducts = useCallback(
    debounce(() => {
      fetchProducts();
    }, 300),
    [fetchProducts]
  );

  useEffect(() => {
    if (hoveredCategory !== null && !fetchCalled) {
      const selectedCategory = categoryMap[hoveredCategory];
      if (selectedCategory) {
        if (selectedCategory.key === "newProducts") {
          setHoveredProducts(brandData?.noParams?.brands || []);
        } else if (selectedCategory.key === "bestSeller") {
          setHoveredProducts(brandData?.bestSeller?.brands || []);
        } else {
          setHoveredProducts(brandData?.[selectedCategory.value]?.brands || []);
        }
        setFetchCalled(true);
      }
    }
  }, [
    hoveredCategory,
    fetchCalled,
    debouncedFetchProducts,
    setBestSeller,
    setSelectedCategory,
  ]);

  const handleMouseEnter = (categoryId) => {
    setNextPage(1);
    if (hoveredCategory !== categoryId) {
      setHoveredCategory(categoryId);
      setFetchCalled(false);
    }
  };

  const handleCategoryClick = (categoryId) => {
    setSelectedCategoryId(categoryId);
    const selectedCategory = categoryMap[categoryId];
    if (selectedCategory) {
      if (selectedCategory.key === "bestSeller") {
        setSelectedCategory("");
        setCheckCategory("");
        onCategoryChange("");
        setBestSeller(selectedCategory.value);
      } else {
        setBestSeller("");
        setSelectedCategory(selectedCategory.value);
        setCheckCategory(selectedCategory.value);
        onCategoryChange(selectedCategory.value);
      }
      debouncedFetchProducts();
    }
  };

  const handleOnItemClick = (item) => {
    addToCartHandler({
      price: item.price,
      name: item.itemName,
      id: item.itemNum,
      brand: item.itemBrand,
      image: item.imageFilename,
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        event.target.closest(".category-content") === null &&
        event.target.closest(".category-item") === null
      ) {
        setHoveredCategory(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="category-container">
      <div className="category-bar">
        <ul className={`category-list flex flex-wrap items-center justify-evenly w-full overflow-x-auto ${loading ? "disabled" : ""}`}>
          <li
            key={newProducts.id}
            className={`category-item text-xs px-2 py-1 ${selectedCategoryId === newProducts.id ? "selected" : ""}`}
            onMouseEnter={() => !loading && handleMouseEnter(newProducts.id)}
            onClick={() => !loading && handleCategoryClick(newProducts.id)}
          >
            <FontAwesomeIcon
              icon={newProducts.icon}
              className="mr-2 text-xs"
            />
            {newProducts.name}
          </li>
          <li
            key={bestSellers.id}
            className={`category-item text-xs px-2 py-1 ${selectedCategoryId === bestSellers.id ? "selected" : ""}`}
            onMouseEnter={() => !loading && handleMouseEnter(bestSellers.id)}
            onClick={() => !loading && handleCategoryClick(bestSellers.id)}
          >
            <FontAwesomeIcon
              icon={bestSellers.icon}
              className="mr-2 text-xs"
            />
            {bestSellers.name}
          </li>
          {otherCategories.map((category) => (
            <li
              key={category.id}
              className={`category-item text-xs px-2 py-1 ${selectedCategoryId === category.id ? "selected" : ""}`}
              onMouseEnter={() => !loading && handleMouseEnter(category.id)}
              onClick={() => !loading && handleCategoryClick(category.id)}
            >
              <FontAwesomeIcon
                icon={category.icon}
                className="mr-2 text-xs"
              />
              {category.name}
            </li>
          ))}
        </ul>
      </div>
      {loading && <div className="loading-line"></div>}
      <div>
        {[newProducts, bestSellers, ...otherCategories].map((category) => (
          <div
            key={category.id}
            className={`p-5 category-content ${
              hoveredCategory === category.id ? "show" : ""
            }`}
            onMouseLeave={() => setHoveredCategory(null)}
          >
            <ul className="grid-container">
              {hoveredCategory === category.id ? (
                hoveredProducts.slice(0, 15).map((product, index) => (
                  <li
                    key={index}
                    className="grid-item"
                    onClick={() => handleOnItemClick(product)}
                  >
                    {product.itemName}
                  </li>
                ))
              ) : (
                <li>No items available</li>
              )}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addToCartHandler: (data) => dispatch(addToCart(data)),
});

export default connect(null, mapDispatchToProps)(CategoryBar);