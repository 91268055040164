import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles.css";

const Card = ({
  searchQuery,
  setSearchQuery,
  handleChange,
  handleSearch,
  // result,
  // search,
  // loading,
  // setLoading,
  // setSearchQuery,
  // setSearchQuery1,
  // addToCart,
  selectedBrand,
  setSelectedBrand,
  selectedCategory,
  setSelectedCategory,
  // setResult,
  setCheckCategory,
  onCategoryChange,
  fetchProducts,
  fetchBrands,
  brands
}) => {
  // const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchBrands();
    fetchCategories();
  }, []);

  // const fetchBrands = async () => {
  //   try {
  //     const requestOptions = {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({}),
  //     };

  //     const response = await fetch(
  //       "https://apis.joonbeauty.com:550/getItemBrands",
  //       requestOptions
  //     );
  //     const data = await response.json();
  //     setBrands(data.data);
  //     // console.log("Fetched brands:", data.data);
  //   } catch (error) {
  //     console.error("Error fetching brands:", error);
  //     toast.error("Failed to fetch brands. Please try again later.", {
  //       position: "top-center",
  //     });
  //   }
  // };

  const fetchCategories = async () => {
    try {
      const response = await fetch(
        // "https://apis.joonbeauty.com:550/ItemCategories",
        "https://apis.joonbeauty.com:550/ItemCategories",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        }
      );
      const data = await response.json();
      setCategories(data.data);
      // console.log("Fetched categories:", data.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
      toast.error("Failed to fetch categories. Please try again later.", {
        position: "top-center",
      });
    }
  };

  const clearSelections = () => {
    setSelectedBrand("");
    setSelectedCategory("");
    setSearchQuery("");
    // setResult([]);
    setCheckCategory("");
    onCategoryChange("");
    fetchProducts("", "", "", "");
    fetchProducts();
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let inputWidth;
  if (windowWidth >= 1536) {
    inputWidth = '42rem';
  } else if (windowWidth >= 1023) {
    inputWidth = '25rem';
  } else if (windowWidth >= 768) {
    inputWidth = '20rem';
  } else {
    inputWidth = '100%';
  }

  return (
    <div className="bg-white shadow-xl p-1 rounded-3xl lg:flex md:flex items-center justify-between lg:gap-4 gap-1 mb-3 p-3">
      <div className="flex justify-between px-2 mb-2 lg:mb-0">
        <input
          type="text"
          placeholder="Search your product..."
          style={{ width: inputWidth }}
          className="bg-gray-100 text-xs border-green-100 focus:outline-none flex-grow-0 p-3 rounded-full text-gray-800"
          onChange={handleChange}
          value={searchQuery}
        />
        {/* <div className="flex items-center lg:bg-[#eee] rounded-full lg:p-5 md:p-3 p-1">
          <svg
            stroke="currentColor"
            fill="#222"
            strokeWidth="0"
            viewBox="0 0 16 16"
            className="cursor-pointer"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
            onClick={fetchProducts}
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
          </svg>
        </div> */}
      </div>
      <div className="flex justify-between px-2">
        <select
          className="custom-select text-black text-sm lg:px-4 lg:py-2 py-1 p-1 rounded-full border border-gray-200 xl:w-60 lg:w-40 w-24 mr-1 custom-label"
          value={selectedBrand}
          onChange={(e) => setSelectedBrand(e.target.value)}
        >
          <option value="">Brand</option>
          {brands.map((brand) => (
            <option key={brand.itemBrand} value={brand.itemBrand}>
              {brand.itemBrand}
            </option>
          ))}
        </select>
        <select
          className="custom-select text-black text-sm lg:px-4 lg:py-2 py-1 rounded-full border border-gray-200 xl:w-60 lg:w-40 w-32 mr-1 custom-label"
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          <option value="">Category</option>
          {categories.map((category) => (
            <option key={category.categoryName} value={category.categoryName}>
              {category.categoryName}
            </option>
          ))}
        </select>
        <button
          className="text-black text-sm lg:px-4 lg:py-2 py-1 rounded-full border border-gray-200 xl:w-60 lg:w-40 w-32 custom-label"
          onClick={clearSelections}
        >
          Clear
        </button>
      </div>
    </div>
  );
};

export default Card;
