import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchCategoriesRequest, fetchCategoriesSuccess, fetchCategoriesFailure } from "../../service/actions/actions";
import { faBasketShopping } from "@fortawesome/free-solid-svg-icons";

const SideBar = ({ fetchCategoriesRequest, fetchCategoriesSuccess, fetchCategoriesFailure }) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        fetchCategoriesRequest();
        setLoading(true);
        const response = await fetch(
          "https://apis.joonbeauty.com:550/ItemCategories",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({}),
          }
        );
        const data = await response.json();
        fetchCategoriesSuccess(data.data);
        setCategories(data.data.map(category => category.categoryName));
        setLoading(false);
      } catch (error) {
        fetchCategoriesFailure(error.message);
        setError(error.message);
        setLoading(false);
      }
    };
    fetchData();
  }, [fetchCategoriesRequest, fetchCategoriesSuccess, fetchCategoriesFailure]);

  const handleCategoryClick = (categoryName) => {
    navigate(`/category/${categoryName}`);
  };

  if (loading) {
    return <div>Loading categories...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="border w-80 text-center pt-2 bg-white rounded-lg px-12 ">
      {categories.map((category, index) => (
        <div key={index} className="flex items-center py-1 cursor-pointer" onClick={() => handleCategoryClick(category)}>
          <FontAwesomeIcon
            className="px-1 py-1 mx-2 rounded-full text-[10px] text-red-800 bg-slate-300"
            icon={faBasketShopping}
          />
          <p className="text-black">{category}</p>
        </div>
      ))}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchCategoriesRequest: () => dispatch(fetchCategoriesRequest()),
  fetchCategoriesSuccess: (data) => dispatch(fetchCategoriesSuccess(data)),
  fetchCategoriesFailure: (error) => dispatch(fetchCategoriesFailure(error)),
});

export default connect(null, mapDispatchToProps)(SideBar);