import React, { useState, useEffect } from "react";
// import Categories from "../../Components/Categories/Categories";
// import Qualities from "../../Components/Qualities/Qualities";
// import Product from "../../Components/Product/Product";
// import Carousal from "../../Components/Slider/Carousal";
import Navbar from "../../Components/Navbar/Navbar";
import Footerbar from "../../Components/FooterBar/Footerbar";
import {
  searchRequest,
  searchSuccess,
  searchFailure,
} from "../../service/actions/actions";
import { connect } from "react-redux";
import Loader from "../../Components/Loader/Loader";
// import Section from "../../Components/NewSection/Section";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faChevronUp,
//   faChevronDown,
//   faChevronCircleUp,
// } from "@fortawesome/free-solid-svg-icons";
import Catalog from "../../Components/Catalog/Catalog";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import CategoryBar from "../../Components/CategoryBar/CategoryBar";
import "./index.css";
// import { useNavigate } from "react-router-dom";

const Home = ({
  handleDoneClick,
  handleChange,
  loading,
  searchQuery,
  searchQuery1,
  setLoading,
  result,
  search,
  setResult,
  showPopup,
  setShowPopup,
  setSearchQuery,
  setSearchQuery1,
  selectedBrand,
  setSelectedBrand,
  selectedCategory,
  setSelectedCategory,
  handleInfiniteScroll,
  handleOnChange,
  hasMore,
  setHasMore
}) => {
  // const navigate = useNavigate();
  const [isInstallable, setIsInstallable] = useState(false);
  // const [showToTopButton, setShowToTopButton] = useState(false);
  // const [showToBottomButton, setShowToBottomButton] = useState(false);
  // const [showToMiddleButton, setShowToMiddleButton] = useState(false);

  useEffect(() => {
    const isPWAInstallable = () => {
      const isSupported = "beforeinstallprompt" in window;
      const meetsInstallCriteria = true;
      return isSupported && meetsInstallCriteria;
    };
    setIsInstallable(isPWAInstallable());
  }, []);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollTop = window.scrollY;
  //     const windowHeight = window.innerHeight;
  //     const bodyHeight = document.body.scrollHeight;

  //     setShowToTopButton(scrollTop > 300);

  //     if (bodyHeight - scrollTop > windowHeight * 1.5) {
  //       setShowToMiddleButton(true);
  //       setShowToBottomButton(true);
  //     } else if (bodyHeight - scrollTop > windowHeight) {
  //       setShowToMiddleButton(false);
  //       setShowToBottomButton(true);
  //     } else {
  //       setShowToMiddleButton(false);
  //       setShowToBottomButton(false);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // const scrollToTop = () => {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // };

  // const scrollToBottom = () => {
  //   window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  // };

  // const scrollToMiddle = () => {
  //   const windowHeight = window.innerHeight;
  //   const middlePosition = document.body.scrollHeight / 2 - windowHeight / 2;
  //   window.scrollTo({ top: middlePosition, behavior: "smooth" });
  // };

  /////////

  window.addEventListener("beforeinstallprompt", (event) => {
    // console.log("beforeinstallprompt event triggered.");
    try {
      event.preventDefault();
      window.deferredPrompt = event;
      const preference = localStorage.getItem("installpreference");
      if (preference !== "installed") {
        setIsInstallable(true);
      }
    } catch (error) {
      // console.log(error, "error");
    }
  });

  const installPWA = () => {
    // console.log("Attempting to install PWA...");
    if (window.deferredPrompt) {
      // console.log("Deferred prompt available. Prompting user...");
      window.deferredPrompt.prompt();
      window.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          // console.log("User accepted the A2HS prompt");
          setIsInstallable(false);
          localStorage.setItem("installpreference", "installed");
        } else {
          // console.log("User dismissed the A2HS prompt");
          localStorage.setItem("installpreference", "false");
          setIsInstallable(false);
        }
        window.deferredPrompt = null;
      });
    } else {
      // console.log("No deferred prompt available.");
    }
  };

  const handleInstallApp = () => {
    try {
      setIsInstallable(false);
      installPWA();
    } catch (error) {
      console.log(error, "error");
    }
  };

  return (
    <div className="bg-slate-100 ">
      <ToastContainer />
      {loading && <Loader />}
      <Navbar
        installPWA={handleInstallApp}
        loading={loading}
        handleSearch={handleDoneClick}
        handleChange={handleChange}
        searchQuery={searchQuery}
        setLoading={setLoading}
        isInstallable={isInstallable}
        result={result}
        showPopup={showPopup}
        setSearchQuery={setSearchQuery}
        setShowPopup={setShowPopup}
        selectedBrand={selectedBrand}
        setSelectedBrand={setSelectedBrand}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
      {/* <CategoryBar/> */}
      {/* <div>
        <Carousal
          installPWA={installPWA}
          handleChange={handleChange}
          searchQuery={searchQuery}
          setLoading={setLoading}
          handleSearch={handleDoneClick}
          loading={loading}
          setShowPopup={setShowPopup}
          result={result}
          showPopup={showPopup}
          setSearchQuery={setSearchQuery}
          selectedBrand={selectedBrand}
          setSelectedBrand={setSelectedBrand}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          bgcolor="bg-slate-100"
          padding="md:pt-10"
        />
      </div> */}
      <Catalog
        handleSearch={handleDoneClick}
        handleChange={handleOnChange}
        searchQuery={searchQuery}
        searchQuery1={searchQuery1}
        result={result}
        setResult={setResult}
        search={search}
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        setSearchQuery={setSearchQuery}
        setSearchQuery1={setSearchQuery1}
        setLoading={setLoading}
        selectedBrand={selectedBrand}
        setSelectedBrand={setSelectedBrand}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        handleInfiniteScroll={handleInfiniteScroll}
        hasMore={hasMore}
        setHasMore={setHasMore}
      />
      {/* <div className="container mx-auto">
        <Qualities />
      </div>
      <div>
        <Section />
      </div>
      <div className="mt-9 sm:mt-0">
        <Categories />
      </div>
      <div>
        <Product />
      </div> */}
      <div>
        <Footerbar />
      </div>
      {/* {showToTopButton && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-5 right-5 bg-gray-300 text-red-900 p-3 rounded-full shadow-lg"
        >
          <FontAwesomeIcon icon={faChevronUp} size="lg" />
        </button>
      )}
      {showToBottomButton && (
        <button
          onClick={scrollToBottom}
          className="fixed bottom-24 right-5 bg-gray-300 text-red-900 p-3 rounded-full shadow-lg"
        >
          <FontAwesomeIcon icon={faChevronDown} size="lg" />
        </button>
      )}
      {showToMiddleButton && (
        <button
          onClick={scrollToMiddle}
          className="fixed bottom-14 right-5 bg-gray-300 text-red-900 p-3 rounded-full shadow-lg"
        >
          <FontAwesomeIcon icon={faChevronCircleUp} size="lg" />
        </button>
      )} */}
    </div>
  );
};

const mapDispatchToProps = {
  searchRequest,
  searchSuccess,
  searchFailure,
};

export default connect(null, mapDispatchToProps)(Home);