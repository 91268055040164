import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  addToCart,
  increaseQuantity,
  decreaseQuantity,
} from "../../service/actions/actions";
import noimage from "../../Assets/no-image-FD.png";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../Loader/Loader";
import { PlusIcon } from "@heroicons/react/24/outline";
import "./styles.css";

const ProductSection = ({
  products,
  hasMore,
  fetchProducts,
  addToCartHandler,
  setLoading,
  loading,
  isLoggedIn,
}) => {
  const BASE_IMAGE_URL = "https://apis.joonbeauty.com:550";
  const [cartItems, setCartItems] = useState([]);
  const [selectedMode, setSelectedMode] = useState({});
  const [boxCounts, setBoxCounts] = useState({});

  useEffect(() => {
    if (products.length > 0) {
      const defaultMode = products.reduce((acc, product) => {
        acc[product.itemNum] = "piece";
        return acc;
      }, {});
      setSelectedMode(defaultMode);
      setLoading(false);
    }
  }, [products, setLoading]);

  useEffect(() => {
    console.log("Selected mode updated:", selectedMode);
  }, [selectedMode]);

  const handleAddToCart = (item) => {
    const addToCart = {
      price: item.price,
      name: item.itemName,
      id: item.itemNum,
      image: item.imageFilename,
      brand: item.itemBrand,
      barcode: item.barcode,
      quantity: Math.max(3, item.quantity || 3),
    };

    addToCartHandler(addToCart);
  };

  const handleIncreaseQuantity = (product) => {
    const mode = selectedMode[product.itemNum] || "piece";
    const quantityIncrement = mode === "box" ? 72 : 3;

    const existingProduct = cartItems.find(
      (item) => item.itemNum === product.itemNum
    );

    if (existingProduct) {
      existingProduct.quantity += 3;
      setCartItems([...cartItems]);
    } else {
      product.quantity = quantityIncrement;
      cartItems.push(product);
      setCartItems([...cartItems]);
    }

    if (mode === "box") {
      setBoxCounts({
        ...boxCounts,
        [product.itemNum]: (boxCounts[product.itemNum] || 1) + 1,
      });
    }
  };

  const handleDecreaseQuantity = (product) => {
    const mode = selectedMode[product.itemNum] || "piece";
    const quantityDecrement = mode === "box" ? 72 : 3;

    const existingProduct = cartItems.find(
      (item) => item.itemNum === product.itemNum
    );

    if (existingProduct && existingProduct.quantity > quantityDecrement) {
      existingProduct.quantity -= 3;
      setCartItems([...cartItems]);
    }

    if (mode === "box" && boxCounts[product.itemNum] > 1) {
      setBoxCounts({
        ...boxCounts,
        [product.itemNum]: boxCounts[product.itemNum] - 1,
      });
    }
  };

  const handleModeChange = (product, mode) => {
    const currentMode = selectedMode[product.itemNum] || "piece";
    const currentQuantity =
      cartItems.find((item) => item.itemNum === product.itemNum)?.quantity || 3;

    if (currentMode !== mode) {
      const newQuantity =
        mode === "box"
          ? Math.ceil(currentQuantity / 72) * 72
          : Math.ceil(currentQuantity / 3) * 3;

      const updatedCartItems = cartItems.map((item) =>
        item.itemNum === product.itemNum
          ? { ...item, quantity: newQuantity }
          : item
      );

      setCartItems(updatedCartItems);
      setBoxCounts({
        ...boxCounts,
        [product.itemNum]: mode === "box" ? Math.ceil(newQuantity / 72) : 0,
      });

      setSelectedMode((prevState) => ({
        ...prevState,
        [product.itemNum]: mode,
      }));
    }
  };

  const handleClearQuantity = (product) => {
    const existingProduct = cartItems.find(
      (item) => item.itemNum === product.itemNum
    );
    if (existingProduct) {
      existingProduct.quantity = 0;
      setCartItems([...cartItems]);
    } else {
      product.quantity = 0;
      cartItems.push(product);
      setCartItems([...cartItems]);
    }

    setBoxCounts({
      ...boxCounts,
      [product.itemNum]: 0,
    });
  };

  return (
    <div className="bg-white shadow-md p-3 rounded-[40px] col-span-full group mt-3">
      <div className="border border-gray-200 rounded-[40px] bg-white lg:px-2 py-2 group-hover:!bg-white mx-2 lg:mx-0">
        {products.length === 0 && !loading ? (
          <div className="text-center p-5">
            <p className="text-gray-500">No Product Found...</p>
          </div>
        ) : (
          <InfiniteScroll
            dataLength={products.length}
            next={fetchProducts}
            hasMore={hasMore}
            loader={<Loader />}
          >
            {products.map((product, index) => (
              <div
                key={`${product.itemNum}-${index}`}
                className={`custom-border lg:grid lg:grid-cols-7 min-w-max p-3 cardInner transition-shadow duration-300 ease-in-out ${
                  index % 2 === 0 ? "bg-white" : "bg-gray-100"
                }`}
              >
                <div className="lg:col-span-2 flex items-center lg:gap-3 h-20">
                  <div className="px-2 rounded-[20px]">
                    <img
                      className="object-cover w-16 h-16"
                      src={
                        product.imageFilename
                          ? `${BASE_IMAGE_URL}${product.imageFilename}`
                          : noimage
                      }
                      alt={product.itemName}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = noimage;
                      }}
                    />
                  </div>
                  <div>
                    <h5 className="text-sm font-semibold leading-6">
                      {product.itemBrand || "Brand Name"}
                    </h5>
                    <p className="text-black text-sm truncate lg:w-64 w-40">
                      {product.itemName}
                    </p>
                  </div>
                  {isLoggedIn &&
                    cartItems.find((item) => item.itemNum === product.itemNum)
                      ?.quantity > 0 && (
                      <p className="text-red-700 lg:ml-0 text-sm mt-1 lg:hidden">
                        Total: $
                        {(
                          product.price *
                          (selectedMode[product.itemNum] === "box"
                            ? boxCounts[product.itemNum] * 72
                            : cartItems.find(
                                (item) => item.itemNum === product.itemNum
                              )?.quantity || 0)
                        ).toFixed(2)}
                      </p>
                    )}
                </div>
                <div className="col-span-2 flex items-center lg:justify-around text-center lg:w-auto w-48">
                  <div className="flex items-center gap-2 lg:hidden">
                    <div className="lg:w-1 w-20 lg:ml-0 h-3 bg-success rounded-full"></div>
                  </div>
                  <div>
                    <p className="text-black hidden lg:block text-sm">
                      Product Code
                    </p>
                    <p className="text-gray-500 mr-3 text-sm">
                      {product.itemNum}
                    </p>
                  </div>
                  <div className="lg:w-20">
                    <p className="text-black hidden lg:block text-sm">
                      Barcode
                    </p>
                    <p className="text-black text-sm lg:bg-transparent bg-gray-200 rounded-3xl px-2">
                      {product.barcode}
                    </p>
                  </div>
                </div>
                <div className="col-span-3 gap-2 lg:gap-3 flex lg:justify-between justify-around items-center mt-2 mb-2 lg:w-auto w-48">
                  <div className="flex flex-col items-center w-24">
                    {isLoggedIn && (
                      <h4 className="font-semibold text-sm">
                        ${product.price}
                      </h4>
                    )}
                    {isLoggedIn &&
                      cartItems.find((item) => item.itemNum === product.itemNum)
                        ?.quantity > 0 && (
                        <div className="lg:flex hidden">
                          <p className="text-red-700 text-sm mt-1">
                            Total: $
                            {(
                              product.price *
                              (selectedMode[product.itemNum] === "box"
                                ? boxCounts[product.itemNum] * 72
                                : cartItems.find(
                                    (item) => item.itemNum === product.itemNum
                                  )?.quantity || 0)
                            ).toFixed(2)}
                          </p>
                        </div>
                      )}
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="flex items-center">
                      <div className="md:w-24 w-20 lg:h-12 h-10 border border-gray-200 flex rounded-[17px]">
                        <button
                          className="w-8 flex items-center justify-center"
                          onClick={() => handleDecreaseQuantity(product)}
                        >
                          -
                        </button>
                        <input
                          className="w-10 outline-none text-center bg-transparent text-sm"
                          type="number"
                          value={
                            selectedMode[product.itemNum] === "box"
                              ? boxCounts[product.itemNum] || 0
                              : cartItems.find(
                                  (item) => item.itemNum === product.itemNum
                                )?.quantity || 0
                          }
                          readOnly
                        />
                        <button
                          className="w-8 flex items-center justify-center"
                          onClick={() => handleIncreaseQuantity(product)}
                        >
                          +
                        </button>
                      </div>
                    </div>
                    {selectedMode[product.itemNum] === "box" && (
                      <div className="text-sm mt-1">
                        72 X {boxCounts[product.itemNum] || 1} PCS
                      </div>
                    )}
                  </div>
                  <div className="flex lg:flex-row flex-col justify-between items-center">
                    <div className="flex lg:flex-row flex-col items-start">
                      <div className="flex items-center custom-radio lg:mr-4 mb-2 lg:mb-0">
                        <input
                          type="radio"
                          id={`piece-${product.itemNum}`}
                          name={`mode-${product.itemNum}`}
                          value="piece"
                          checked={selectedMode[product.itemNum] === "piece"}
                          onChange={() => handleModeChange(product, "piece")}
                          className="mr-1"
                        />
                        <label
                          htmlFor={`piece-${product.itemNum}`}
                          className="text-sm"
                        >
                          Piece
                        </label>
                      </div>
                      <div className="flex items-center custom-radio">
                        <input
                          type="radio"
                          id={`box-${product.itemNum}`}
                          name={`mode-${product.itemNum}`}
                          value="box"
                          checked={selectedMode[product.itemNum] === "box"}
                          onChange={() => handleModeChange(product, "box")}
                          className="mr-1"
                        />
                        <label
                          htmlFor={`box-${product.itemNum}`}
                          className="text-sm"
                        >
                          Box
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-center">
                    <button
                      className="bg-gray-200 p-2 rounded-md text-sm min-w-[60px]"
                      onClick={() => handleClearQuantity(product)}
                    >
                      Clear
                    </button>
                  </div>
                  <div className="relative w-fit">
                    <button
                      type="button"
                      className="bg-white p-3 rounded-2xl border border-gray-300 group hover:bg-gray-100 hover:text-red-700 lg:w-12 lg:h-12 w-12 h-12 flex items-center justify-center"
                      onClick={() => handleAddToCart(product)}
                    >
                      <PlusIcon className="w-6 h-6" />
                    </button>
                    {/* {product.quantity > 0 && (
                      <div className="absolute -top-2 -right-2 w-6 h-6 flex items-center justify-center bg-red-700 text-white text-xs rounded-full">
                        {product.quantity}
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  addToCartHandler: (data) => dispatch(addToCart(data)),
  increaseQuantity: (itemNum) => dispatch(increaseQuantity(itemNum)),
  decreaseQuantity: (itemNum) => dispatch(decreaseQuantity(itemNum)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductSection);