import bold from './Assets/bold.png';
import cala from './Assets/cala.png';
import dinia from './Assets/dinia.jpeg';
import jcat from './Assets/jcat.png';
import lacolor from'./Assets/lacolor.webp';
import lagril from './Assets/lagril.png';
import Nyx from './Assets/Nyx.png';
import kk from './Assets/kk.png';
import sassi from './Assets/sassi.jpeg';
import moria from './Assets/moria.png';
import wild from './Assets/wild.png'
const Data = [
 
  {
    imag: "	https://www.makeupcityshop.com/cdn/shop/files/Main-banner.jpg?v=1707742969",
  },
  {
    imag: "https://www.makeupcityshop.com/cdn/shop/files/1920x600_Main_banner_3.jpg?v=1706793614",
  },
  {
    imag: "	https://www.masarratmakeup.com/cdn/shop/files/Web_Banner_Foundation.jpg?v=1703746593",
  },
  {
    imag: "https://www.masarratmakeup.com/cdn/shop/files/DEsktop_Banner.png?v=1707842354",
  },
  {
    imag: "https://iloveluscious.com/cdn/shop/files/Velvet-Reign_1600x.png?v=1706876623",
  },
  {
    imag: "https://icms-image.slatic.net/images/ims-web/13178aee-d424-487b-aafa-6701826fa6ed.jpg",
  },
];

const CategoriesData = [
  {
    imag: bold,
    title: "BOLD HOLD",
  },
  {
    imag: cala,
    title: "BLACK CALA",
  },
  {
    imag: wild,
    title: "WILD GROWTH",
  },
  {
    imag: dinia,
    title: "DIANE",
  },
  {
    imag: jcat,
    title: "JCAT",
  },
  {
    imag: lacolor,
    title: "LA COLOR",
  },
  {
    imag: lagril,
    title: "LA GIRL",
  },
  {
    imag: Nyx,
    title: "NYX",
  },
  {
    imag: sassi,
    title: "SASSI",
  },
  {
    imag: kk,
    title: "K&K BODY OIL",
  },
  {
    imag: moria,
    title: "MOIRA",
  },
  
];

export { Data, CategoriesData };