import React from 'react';
import logo from "../../Assets/logo.png";
// import { useNavigate } from "react-router-dom";

const Header = () => {
  // const navigate = useNavigate();

  // const handleQuickShop = () => {
  //   navigate("/quickshop");
  //   // console.log("Quick Shop button clicked!");
  // };
  
  return (
    <div className="md:flex flex justify-between bg-[#fff]  lg:hidden shadow:md">
        <div className="h-11 w-32 p-2">
          <img src={logo} alt="" />
        </div>
        <div className="  ">
          <div className="mt-2 mx-2.5 ">
            {/* <button
              onClick={togglePWAModal}
              className="text-white border px-1 rounded "
            >
              Install App
            </button> */}
            {/* <button
              onClick={handleQuickShop}
              className="text-black border border-black px-1 rounded "
            >
              Quick Shop
            </button> */}
            {/* <button onClick={handleQuickShop}>
              <FontAwesomeIcon icon={faBars} size="lg" className="text-white ml-4" />
            </button> */}
          </div>
        </div>
      </div>
  );
};

export default Header;
