import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchBrandData = () => {
  const [brandData, setBrandData] = useState({});
  
  useEffect(() => {
    const fetchBrandData = async () => {
        try {
          console.log("Fetching brand data...");
          
          const categories = ["LIP", "EYES", "FACE", "ACCESSORIES"];
          const bestSeller = "bestSeller";
          
          const allData = {};
      
          for (const category of categories) {
            const response = await axios.post('https://apis.joonbeauty.com:550/getAllBrands', {
              page: 1,
              perPage: 15,
              category,
              bestSeller: '',
            });
      
            console.log(`Response for ${category}:`, response.data);
      
            if (response.data && response.data.data.length > 0) {
              allData[category] = {
                brands: response.data.data,
              };
            }
          }
      
          const bestSellerResponse = await axios.post('https://apis.joonbeauty.com:550/getAllBrands', {
            page: 1,
            perPage: 15,
            category: '',
            bestSeller,
          });
      
          console.log(`Response for Best Seller:`, bestSellerResponse.data);
      
          if (bestSellerResponse.data && bestSellerResponse.data.data.length > 0) {
            allData[bestSeller] = {
              brands: bestSellerResponse.data.data,
            };
          }

          const noParamsResponse = await axios.post('https://apis.joonbeauty.com:550/getAllBrands', {
            page: 1,
            perPage: 15,
          });
    
          console.log(`Response without parameters:`, noParamsResponse.data);
    
          if (noParamsResponse.data && noParamsResponse.data.data.length > 0) {
            allData['noParams'] = {
              brands: noParamsResponse.data.data,
            };
          }    
      
          setBrandData(allData);
          console.log("Fetched Brand Data:", allData);
      
        } catch (error) {
          console.error('Error fetching brand data:', error);
        }
      };      

    fetchBrandData();
  }, []);

  return brandData;
};

export default useFetchBrandData;