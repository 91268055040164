import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Modal from "../Modal/Modal";
import {
  faUser,
  faCartShopping,
  faHome
} from "@fortawesome/free-solid-svg-icons";
import "./Footerbar.css"

const Footerbar = ({ cartItemsCount, isLoggedIn  }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    document.body.style.overflow = isModalOpen ? "" : "hidden";
  };

  const handleAccountClick = () => {
    if (isLoggedIn) {
      navigate("/account");
    } else {
      toggleModal();
    }
  };
 
  return (
    <div>
      <div className="bg border-t py-2 left-0 bottom-0 overflow-hidden fixed w-full z-10 lg:hidden sm:flex visible">
        <nav className="w-full">
          <ul className="container mx-auto flex justify-between items-center px-4 sm:px-6 lg:px-8 space-x-4">
            <li className="flex-grow flex justify-center">
              <Link to="/" className="block text-black text-center px-3 text-css">
                <FontAwesomeIcon className="text-xl text-css" icon={faHome} />
                <p className="mt-1 text-sm">Home</p>
              </Link>
            </li>
            <li className="flex-grow flex justify-center relative">
              <Link to="/cart" className="block text-black text-center px-3">
                <FontAwesomeIcon className="text-xl text-css" icon={faCartShopping} />
                <p className="mt-1 text-sm text-css">Cart</p>
                {cartItemsCount > 0 && (
                  <span className="absolute top-0 right-0 bg-yellow-400 text-black rounded-full px-2 text-xs text-css">
                    {cartItemsCount}
                  </span>
                )}
              </Link>
            </li>
            <li className="flex-grow flex justify-center">
              <button className="block text-black text-center px-3 text-css" onClick={handleAccountClick}>
                <FontAwesomeIcon className="text-xl text-css" icon={faUser} />
                <p className="mt-1 text-sm text-css">Account</p>
              </button>
            </li>
          </ul>
        </nav>
      </div>
      <Modal
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const cartItemsCount = state.cardItems.cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );
  return { cartItemsCount, isLoggedIn: state.auth.isLoggedIn };
};

export default connect(mapStateToProps, null)(Footerbar);