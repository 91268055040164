import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  INCREASE_QUANTITY,
  DECREASE_QUANTITY,
  CLEAR_CART,
  SET_HALF_BOX_QUANTITY,
  SET_FULL_BOX_QUANTITY,
  RESET_QUANTITY,
  SET_QUANTITY_TYPE,
} from "../constants";

const initialState = {
  cartItems: [],
};

const BOX_SIZE = 72;
const MIN_QUANTITY = 3;
const MAX_QUANTITY = 2000;

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      const existingItemIndex = state.cartItems.findIndex(
        (item) => item.id === action.data.id
      );
      if (existingItemIndex !== -1) {
        const updatedCartItems = state.cartItems.map((item, index) => {
          if (index === existingItemIndex) {
            let newQuantity = action.data.quantity
              ? action.data.quantity
              : item.quantity + 1;
            if (newQuantity < MIN_QUANTITY) newQuantity = MIN_QUANTITY;
            if (newQuantity > MAX_QUANTITY) newQuantity = MAX_QUANTITY;
            return { ...item, quantity: newQuantity };
          }
          return item;
        });

        return {
          ...state,
          cartItems: updatedCartItems,
        };
      } else {
        let newQuantity = action.data.quantity ? action.data.quantity : MIN_QUANTITY;
        if (newQuantity < MIN_QUANTITY) newQuantity = MIN_QUANTITY;
        if (newQuantity > MAX_QUANTITY) newQuantity = MAX_QUANTITY;
        return {
          ...state,
          cartItems: [
            ...state.cartItems,
            {
              ...action.data,
              quantity: newQuantity,
              quantityType: "piece",
              boxSize: BOX_SIZE,
            },
          ],
        };
      }

    case REMOVE_FROM_CART:
      return {
        ...state,
        cartItems: state.cartItems.filter((item) => item.id !== action.data),
      };

    case INCREASE_QUANTITY:
      return {
        ...state,
        cartItems: state.cartItems.map((item) =>
          item.id === action.id
            ? {
                ...item,
                quantity: item.quantityType === "box"
                  ? Math.min(item.quantity + BOX_SIZE, MAX_QUANTITY)
                  : Math.min(item.quantity + 1, MAX_QUANTITY),
              }
            : item
        ),
      };

    case DECREASE_QUANTITY:
      return {
        ...state,
        cartItems: state.cartItems.map((item) =>
          item.id === action.id
            ? {
                ...item,
                quantity: item.quantityType === "box"
                  ? Math.max(item.quantity - BOX_SIZE, MIN_QUANTITY)
                  : Math.max(item.quantity - 1, MIN_QUANTITY),
              }
            : item
        ),
      };

    case CLEAR_CART:
      return {
        ...state,
        cartItems: [],
      };

    case SET_HALF_BOX_QUANTITY:
      return {
        ...state,
        cartItems: state.cartItems.map((item) =>
          item.id === action.id
            ? { ...item, quantity: Math.min(item.quantity + BOX_SIZE / 2, MAX_QUANTITY) }
            : item
        ),
      };

    case SET_FULL_BOX_QUANTITY:
      return {
        ...state,
        cartItems: state.cartItems.map((item) =>
          item.id === action.id
            ? { ...item, quantity: Math.min(item.quantity + BOX_SIZE, MAX_QUANTITY) }
            : item
        ),
      };

    case RESET_QUANTITY:
      return {
        ...state,
        cartItems: state.cartItems.map((item) =>
          item.id === action.id ? { ...item, quantity: MIN_QUANTITY } : item
        ),
      };

    case SET_QUANTITY_TYPE:
      return {
        ...state,
        cartItems: state.cartItems.map((item) =>
          item.id === action.payload.itemId
            ? { ...item, quantityType: action.payload.quantityType, quantity: action.payload.quantityType === "box"
              ? BOX_SIZE
              : item.quantity }
            : item
        ),
      };

    default:
      return state;
  }
};

export default cartReducer;