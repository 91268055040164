import React, { useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";

const FilterModal = ({
  isOpen,
  onClose,
  brands,
  categories,
  handleBrandSelect,
  handleCategorySelect,
}) => {
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    if (!isOpen) {
      setSelectedBrand("");
      setSelectedCategory("");
    }
  }, [isOpen]);

  useEffect(() => {
    if (brands.length > 0 && selectedBrand === "") {
      setSelectedBrand(brands[0].itemBrand);
    }
  }, [brands]);

  const handleBrandChange = (e) => {
    const selectedBrandValue = e.target.value;
    console.log('Selected Brand:', selectedBrandValue);
    setSelectedBrand(selectedBrandValue);
    handleBrandSelect(selectedBrandValue);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    handleCategorySelect(e.target.value);
  };

  const handleClear = () => {
    setSelectedBrand("");
    setSelectedCategory("");
    handleBrandSelect("");
    handleCategorySelect("");
  };

  const handleSelect = () => {
    handleBrandSelect(selectedBrand);
    handleCategorySelect(selectedCategory);
    onClose();
  };

  return isOpen ? (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-3 rounded-lg shadow-lg max-w-sm w-full mx-3 md:mx-auto">
        <div className="bg-orange-500 text-white text-center rounded-t-lg mb-3 relative">
          <h2 className="text-lg font-semibold py-2">
            Filter Options
            <button
              className="absolute top-0 right-0 text-gray-500 hover:text-gray-800 p-3"
              onClick={onClose}
            >
              <IoClose className="h-6 w-6" />
            </button>
          </h2>
        </div>
        <div className="mb-3">
          <label className="block mb-1 font-medium text-gray-700 text-sm">
            Select Brand
          </label>
          <select
            value={selectedBrand}
            onChange={handleBrandChange}
            className="w-full p-2 border border-gray-300 rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Select a brand</option>
            {brands.map((brand) => (
              <option key={brand.id} value={brand.itemBrand}>
                {brand.itemBrand}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <label className="block mb-1 font-medium text-gray-700 text-sm">
            Select Category
          </label>
          <select
            value={selectedCategory}
            onChange={handleCategoryChange}
            className="w-full p-2 border border-gray-300 rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Select a category</option>
            {categories.map((category) => (
              <option key={category.id} value={category.categoryName}>
                {category.categoryName}
              </option>
            ))}
          </select>
        </div>
        <div className="flex justify-end">
          <button
            onClick={handleClear}
            className="px-3 py-1 bg-gray-400 text-white rounded-lg hover:bg-gray-500 text-sm mr-2"
          >
            Clear
          </button>
          <button
            onClick={handleSelect}
            className={`px-3 py-1 bg-blue-500 text-white rounded-lg hover:bg-blue-600 text-sm ${
              (selectedBrand || selectedCategory) ? "" : "bg-blue-200 cursor-not-allowed"
            }`}
            disabled={!selectedBrand && !selectedCategory}
          >
            Select
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default FilterModal;