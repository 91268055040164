import * as constants from "../constants";
import { toast } from "react-toastify";

//AddToCart
export const addToCart = (data) => {
  return (dispatch, getState) => {
    const { cartItems } = getState().cardItems;
    const existingItem = cartItems.find((item) => item.id === data.id);
    if (existingItem) {
      toast.dismiss();
      toast.error("Item already exists in the cart", {
        position: "top-right",
        autoClose: 3000,
      });
    } else {
      dispatch({
        type: constants.ADD_TO_CART,
        data: data
      });
    }
  };
};

export const removeFromCart = (data) => ({
  type: constants.REMOVE_FROM_CART,
  data: data,
});

export const increaseQuantity = (itemId) => ({
  type: constants.INCREASE_QUANTITY,
  id: itemId,
});

export const decreaseQuantity = (id) => ({
  type: constants.DECREASE_QUANTITY,
  id,
});

export const updateQuantity = (itemId, quantity) => ({
  type: constants.UPDATE_QUANTITY,
  payload: { itemId, quantity },
});

export const clearCart = () => ({
  type: constants.CLEAR_CART,
});

export const setHalfBoxQuantity = (id) => ({
  type: constants.SET_HALF_BOX_QUANTITY,
  id,
});

export const setFullBoxQuantity = (id) => ({
  type: constants.SET_FULL_BOX_QUANTITY,
  id,
});

export const resetQuantity = (id) => ({
  type: constants.RESET_QUANTITY,
  id,
});

export const setQuantityType = (itemId, quantityType) => ({
  type: constants.SET_QUANTITY_TYPE,
  payload: { itemId, quantityType }
});



//Products

export const fetchProductsSuccess = (products) => ({
  type: constants.FETCH_PRODUCTS_SUCCESS,
  products: products,
});

export const fetchProductsFailure = (error) => ({
  type: constants.FETCH_PRODUCTS_FAILURE,
  error: error,
});

//Search

export const searchRequest = () => ({
  type: constants.SEARCH_REQUEST,
});

export const searchSuccess = (results) => {
  // console.log("Search results:", results);
  return {
    type: constants.SEARCH_SUCCESS,
    payload: results,
  };
};

export const searchFailure = (error) => ({
  type: constants.SEARCH_FAILURE,
  payload: error,
});

//Categories
export const fetchCategoriesRequest = () => ({
  type: constants.FETCH_CATEGORIES_REQUEST,
});

export const fetchCategoriesSuccess = (data) => ({
  type: constants.FETCH_CATEGORIES_SUCCESS,
  payload: data,
});

export const fetchCategoriesFailure = (error) => ({
  type: constants.FETCH_CATEGORIES_FAILURE,
  payload: error,
});

// authActions
export const loginRequest = () => ({
  type: constants.LOGIN_REQUEST,
});

export const loginSuccess = (user) => {
  localStorage.setItem('user', JSON.stringify(user));
  return { type: constants.LOGIN_SUCCESS, payload: user };
};


export const loginFailure = (error) => ({
  type: constants.LOGIN_FAILURE,
  payload: error.message,
});

export const logout = () => {
  localStorage.removeItem('user');
  return dispatch => {
    dispatch({ type: constants.LOGOUT });
    dispatch(clearBillingAddress());
  };
};

export const clearBillingAddress = () => ({
  type: constants.CLEAR_BILLING_ADDRESS
});

export const storeUserData = (userData) => ({
  type: constants.STORE_USER_DATA,
  payload: userData,
});

export const requestPayment = (paymentData) => ({
  type: constants.REQUEST_PAYMENT,
  payload: paymentData,
});

export const receivePaymentSuccess = () => ({
  type: constants.RECEIVE_PAYMENT_SUCCESS,
});

export const receivePaymentFailure = (error) => ({
  type: constants.RECEIVE_PAYMENT_FAILURE,
  payload: error,
});

export const updateBillingAddress = (billingAddress) => {
  localStorage.setItem('billingAddress', JSON.stringify(billingAddress));
  return {
    type: constants.UPDATE_BILLING_ADDRESS,
    payload: billingAddress,
  };
};

export const setPaymentDetailsAdded = (value) => ({
  type: constants.SET_PAYMENT_DETAILS_ADDED,
  payload: value,
});

//
export const updateDeliveryAddress = (deliveryAddress) => ({
  type: constants.UPDATE_DELIVERY_ADDRESS,
  payload: deliveryAddress,
});

export const requestAddress = () => ({
  type: constants.REQUEST_ADDRESS,
});

export const receiveAddressSuccess = () => ({
  type: constants.RECEIVE_ADDRESS_SUCCESS,
  //payload: address,
});

export const receiveAddressFailure = (error) => ({
  type: constants.RECEIVE_ADDRESS_FAILURE,
  payload: error,
});

//

// export const requestUpdateDeliveryAddress = () => ({
//   type: constants.REQUEST_UPDATE_DELIVERY_ADDRESS,
// });

// export const updateDeliveryAddressSuccess = (address) => ({
//   type: constants.UPDATE_DELIVERY_ADDRESS_SUCCESS,
//   payload: address,
// });

// export const updateDeliveryAddressFailure = (error) => ({
//   type: constants.UPDATE_DELIVERY_ADDRESS_FAILURE,
//   payload: error,
// });

export const fetchOrdersRequest = () => ({
  type: constants.FETCH_ORDERS_REQUEST,
});

export const fetchOrdersSuccess = (orders) => ({
  type: constants.FETCH_ORDERS_SUCCESS,
  payload: orders,
});

export const fetchOrdersFailure = (error) => ({
  type: constants.FETCH_ORDERS_FAILURE,
  payload: error,
});

export const fetchOrders = () => ({
  type: constants.FETCH_ORDERS,
});