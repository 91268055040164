import React, { useState } from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import {
  loginSuccess,
  loginFailure,
  updateBillingAddress,
  storeUserData,
} from "../../service/actions/actions";
import imagmakeup from "../../Assets/imagmakeup.webp";
import logo from "../../Assets/logo.png";
import Register from "../RegisterModal/Register";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import "./Modal.css";

const Modal = ({
  isModalOpen,
  toggleModal,
  setIsModalOpen,
  loginSuccess,
  loginFailure,
  fromCartPage,
  user,
}) => {
  // const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userCreds, setUserCreds] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = async () => {
    try {
      setLoading(true);
      if (
        //!email.trim() ||
        !phoneNumber.trim()
      ) {
        setErrorMessage("Please enter both email and phone number.");
        setTimeout(() => {
          setErrorMessage("");
        }, 2000);
        return;
      }

      const response = await fetch("https://apis.joonbeauty.com:550/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // email,
          phoneNumber,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        console.log("Login successful:", data);
        if (data.success) {
          // loginSuccess(data);
          dispatch(storeUserData(data.customer));
          setUserCreds(data);
          setIsConfirmationModalOpen(true);
          updateBillingAddress(data.billingAddress);
          setLoading(false);
          toggleModal(false);
          // if (!fromCartPage) {
          //   navigate("/account");
          // }
        } else {
          setErrorMessage("Invalid credentials. Please try again.");
        }
      } else {
        console.error("Login failed:", data.error);
        setErrorMessage("An error occurred while trying to log in.");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Login error:", error);
      setErrorMessage("An error occurred while trying to log in.");
    }
  };

  const toggleRegisterModal = () => {
    setIsModalOpen(false);
    setIsRegisterModalOpen(!isRegisterModalOpen);
  };

  const toggleConfirmationModal = () => {
    setIsConfirmationModalOpen(!isConfirmationModalOpen);
  };

  return (
    <div>
      {isModalOpen && (
        <div className="fixed top-0 right-6 lg:right-0 left-0 z-60 flex items-center h-screen bg-opacity-50 bg-black">
          <div className="relative m-auto">
            {loading && <Loader />}
            <div className="relative bg-white rounded-lg shadow">
              <div className="lg:flex flex-none rounded">
                <div className="hidden lg:flex lg:visible">
                  <img src={imagmakeup} alt="" />
                </div>
                <div className="xl:w-[50vh] width-two">
                  <div className="">
                    <button
                      onClick={toggleModal}
                      className="bg float-right text-sm w-8 h-10 md:h-16 ms-auto inline-flex justify-center items-center shadow-md"
                      type="button"
                    >
                      <svg
                        className="w-3 h-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 14"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                        />
                      </svg>
                      <span className="sr-only bg">Close modal</span>
                    </button>
                  </div>
                  <div className="bg flex justify-center shadow-md">
                    <img
                      src={logo}
                      className="h-10 w-auto md:h-16 p-2"
                      alt=""
                    />
                  </div>
                  <div className="content">
                    <div className="flex justify-center">
                      <h1 className="text-center mt-4 sm:pt-9 pt-3 text-modal text-2xl font-bold">
                        Sign In
                      </h1>
                    </div>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleLogin();
                      }}
                      className="space-y-6 mt-6 mx-4"
                      action="#"
                    >
                      <div className="sm:p-6 md:p-7 p-4 bg-white rounded-lg shadow-md">
                        <div className="space-y-6">
                          <div>
                            <label
                              htmlFor="phoneNumber"
                              className="block mb-2 text-sm font-medium text-gray-800"
                            >
                              Phone Number
                            </label>
                            <input
                              type="text"
                              id="phoneNumber"
                              placeholder="Enter your phone number..."
                              value={phoneNumber}
                              onChange={(e) => setPhoneNumber(e.target.value)}
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                              required
                            />
                          </div>

                          <button
                            type="submit"
                            className="w-full text-white bg-gray-600 hover:bg-black font-medium rounded-lg text-sm px-5 py-3 text-center"
                          >
                            Login to your account
                          </button>
                          {errorMessage && (
                            <div className="text-red-500 text-sm mt-2">
                              {errorMessage}
                            </div>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isConfirmationModalOpen && (
        <ConfirmationModal
          toggleConfirmationModal={toggleConfirmationModal}
          toggleLoginModal={toggleModal}
          fromCartPage={fromCartPage}
          data={userCreds}
          loginSuccess={loginSuccess}
          updateBillingAddress={updateBillingAddress}
          storeUserData={storeUserData}
        />
      )}
      <Register
        isRegisterModalOpen={isRegisterModalOpen}
        toggleRegisterModal={toggleRegisterModal}
        setIsRegisterModalOpen={setIsRegisterModalOpen}
        toggleModal={toggleModal}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  loginSuccess: (user) => dispatch(loginSuccess(user)),
  loginFailure: (error) => dispatch(loginFailure(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
