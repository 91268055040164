import * as constants from "../constants";

const initialState = {
  products: [],
  error: null
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.products,
        error: null
      };
    case constants.FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
};

export default productsReducer;