import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  searchRequest,
  searchSuccess,
  searchFailure,
  logout,
} from "../../service/actions/actions";
import logo from "../../Assets/logo.png";
import {
  faUser,
  faSignOutAlt,
  faShoppingBag,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Modal from "../Modal/Modal";
import PWAModal from "../PWAModal/PWAModal";
import Register from "../RegisterModal/Register";
// import { installPWA } from "../../ServiceWorker";
import Loader from "../../Components/Loader/Loader";
import "./navbar.css";

const Navbar = ({
  isLoggedIn,
  logout,
  cartItemsCount,
  loading,
  installPWA,
  isInstallable,
  setLoading,
  searchResults,
  user,
  selectedBrand,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [showPWAModal, setShowPWAModal] = useState(false);
  const navigate = useNavigate();
  const firstName = user ? user.companyName : "UserName";

  const [hoveredCategory, setHoveredCategory] = useState(null);

  const categories = [
    { id: 1, name: 'Category 1', items: ['Item 1-1', 'Item 1-2', 'Item 1-3'] },
    { id: 2, name: 'Category 2', items: ['Item 2-1', 'Item 2-2'] },
    { id: 3, name: 'Category 3', items: ['Item 3-1', 'Item 3-2', 'Item 3-3', 'Item 3-4'] },
  ];

  const handleInstallPWA = () => {
    installPWA();
  };

  useEffect(() => {
    if (searchResults.length === 0) {
      setLoading(false);
    }
  }, [searchResults, setLoading]);

  useEffect(() => {
    const togglePWAModal = () => {
      setShowPWAModal((prevState) => !prevState);
    };

    if (isInstallable) {
      togglePWAModal();
    }
  }, [isInstallable]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    document.body.style.overflow = isModalOpen ? "" : "hidden";
  };

  const toggleRegisterModal = () => {
    setIsRegisterModalOpen(!isRegisterModalOpen);
  };

  const handleLogout = () => {
    logout();
    navigate("/");
    setIsModalOpen(true);
  };

  const accountClick = () => {
    navigate("/account");
    console.log("brand in navbar: ", selectedBrand);
  };

  return (
    <div className="bg-white fixed top-0 w-full z-10 py-2 md:visible invisible">
      {loading && <Loader />}
      <div className="container mx-auto px-4">
        <nav className="border-gray-200">
          <div className="flex items-center justify-between">
            <div>
              <Link to="/">
                <img src={logo} className="h-12 w-auto" alt="Logo" />
              </Link>
            </div>
            <ul className="flex justify-item justify-start items-start space-x-4 md:space-x-2 lg:space-x-4">
              <li className="hidden md:block relative">
                <Link to="/cart" className="text-css hover:text-black">
                  <FontAwesomeIcon icon={faShoppingBag} className="mr-2" /> CART
                  {cartItemsCount > 0 && (
                    <span className="absolute -top-3 -right-2 bg-black text-white rounded-full px-1 text-xs">
                      {cartItemsCount}
                    </span>
                  )}
                </Link>
              </li>
              {isLoggedIn ? (
                <>
                  <li className="hidden md:block">
                    <button
                      className="pl-3 hover:text-black rounded md:bg-transparent text-css"
                      onClick={accountClick}
                    >
                      <FontAwesomeIcon
                        className="mr-2 text-css"
                        icon={faUser}
                      />
                      {firstName}
                    </button>
                  </li>
                  <li className="hidden md:block">
                    <button
                      className="hover:text-black rounded md:bg-transparent text-css"
                      onClick={handleLogout}
                    >
                      <FontAwesomeIcon
                        className="px-3 text-css"
                        icon={faSignOutAlt}
                      />
                      logout
                    </button>
                  </li>
                </>
              ) : (
                <>
                  <li className="hidden md:block">
                    <button
                      className="rounded md:bg-transparent text-css"
                      onClick={toggleModal}
                    >
                      Login
                    </button>
                  </li>
                </>
              )}
            </ul>
          </div>
        </nav>
        {/* <div className="category-container">
          <div className="category-bar">
            <ul className="category-list">
              {categories.map((category) => (
                <li
                  key={category.id}
                  className="category-item"
                  onMouseEnter={() => setHoveredCategory(category.id)}
                  onMouseLeave={() => setHoveredCategory(null)}
                >
                  {category.name}
                </li>
              ))}
            </ul>
          </div>
          <div className="category-content-container">
            {categories.map((category) => (
              <div
                key={category.id}
                className={`category-content ${
                  hoveredCategory === category.id ? "show" : ""
                }`}
              >
                <ul>
                  {category.items.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div> */}
      </div>
      <Modal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        toggleModal={toggleModal}
      />
      <Register
        isRegisterModalOpen={isRegisterModalOpen}
        toggleRegisterModal={toggleRegisterModal}
        setIsRegisterModalOpen={setIsRegisterModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      {showPWAModal && (
        <PWAModal
          show={showPWAModal}
          handlePwaModal={setShowPWAModal}
          handleInstallApp={handleInstallPWA}
        />
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  const cartItemsCount = state.cardItems.cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );
  return {
    isLoggedIn: state.auth.isLoggedIn,
    companyName: state.auth.companyName,
    user: state.auth.user,
    cartItemsCount: cartItemsCount,
    searchResults: state.searchReducer.searchResults,
  };
};

const mapDispatchToProps = {
  searchRequest,
  searchSuccess,
  searchFailure,
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
