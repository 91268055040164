import React, { useState, useEffect, useRef } from "react";
import { Data } from "../../Data";
import SideBar from "../Sidebar/SideBar";
import PWAModal from "../PWAModal/PWAModal";
import "./slide.css";
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import Header from "../Header/Header";
import SearchResultsPopup from "../SearchBar/SearchResultsPopup";
import FilterModal from "../FilterModal/FilterModal"; // Import the FilterModal component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

const Carousal = ({
  handleSearch,
  handleChange,
  installPWA,
  searchQuery,
  setLoading,
  loading,
  searchResults,
  bgcolor,
  padding,
  text,
  result,
  showPopup,
  setShowPopup,
  setSearchQuery,
  selectedBrand,
  setSelectedBrand,
  selectedCategory,
  setSelectedCategory
}) => {
  const [curr, setCurr] = useState(0);
  const intervalRef = useRef(null);
  const [showPWAModal, setShowPWAModal] = useState(false);
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  // const [selectedBrand, setSelectedBrand] = useState("");
  // const [selectedCategory, setSelectedCategory] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      nextSlide();
    }, 3000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  const prevSlide = () => {
    setCurr((prevCurr) => (prevCurr === 0 ? Data.length - 1 : prevCurr - 1));
  };

  const nextSlide = () => {
    setCurr((prevCurr) => (prevCurr === Data.length - 1 ? 0 : prevCurr + 1));
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleInputChange = (event) => {
    handleChange(event);
  };

  const handleOnSearch = () => {
    handleSearch();
    navigate("/search");
    setShowPopup(false);
  };

  const handleInstallPWA = () => {
    // console.log("Attempting to install PWA...");
    installPWA();
  };

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
    if (!isModalOpen) {
      fetchBrands();
      fetchCategories();
    }
  };

  const handleBrandSelect = (brand) => {
    setSelectedBrand(brand);
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const fetchBrands = async () => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      };

      const response = await fetch(
        "https://apis.joonbeauty.com:550/getItemBrands",
        requestOptions
      );
      const data = await response.json();
      setBrands(data.data);
      console.log("Fetched brands:", data.data);
    } catch (error) {
      console.error("Error fetching brands:", error);
      // Handle error
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch(
        "https://apis.joonbeauty.com:550/ItemCategories",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        }
      );
      const data = await response.json();
      setCategories(data.data);
      // console.log("Fetched categories:", data.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
      // Handle error
    }
  };

  const isMediumScreen = window.innerWidth < 1023;

  return (
    <>
      <Header />
      <div className={`container-fluid h-auto md:mt-12 ${bgcolor}`}>
        <div className={`container mx-auto ${padding} md:pt-10 pt-0 relative`}>
          <div className="lg:flex md:flex-none md:mt-0">
            <div className="hidden lg:flex">
              <SideBar />
            </div>

            <div className="container lg:ms-9 ms-0 sm:mt-0">
              {loading && <Loader />}
              <nav className="my-nav mb-3 md:invisible visible flex lg:hidden">
                <div className="flex flex-wrap items-center md:p-4 justify-between w-[380px]">
                  <div className="flex items-center justify-center w-full">
                    <div className="relative flex items-center w-[360px] md:w-[95vh] border border-gray-300 rounded-lg bg-white mt-3">
                      <button
                        className="text-gray-500 font-semibold bg-gray-100 hover:bg-gray-200 p-2 rounded-l-lg transition-all duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-gray-300"
                        onClick={toggleModal}
                      >
                        <FontAwesomeIcon icon={faFilter} />
                      </button>
                      <div className="h-6 w-px bg-gray-300 mx-2"></div>
                      <input
                        type="text"
                        onChange={handleInputChange}
                        value={searchQuery}
                        onKeyPress={handleKeyPress}
                        id="search-navbar"
                        className="block w-full p-2 text-sm text-gray-900 outline-none bg-white dark:bg-white-700 dark:placeholder-gray-900 dark:text-gray-700"
                        placeholder="Search..."
                      />

                      {/* <button
                        onClick={handleOnSearch}
                        id="done-button"
                        className="absolute right-0 top-0 border py-1 flex items-center pr-3 bg-red-100 pointer-events-auto px-4 my-1.5 rounded mx-4"
                      >
                        <svg
                          className="w-4 h-4 text-black cursor-pointer"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 20"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                          />
                        </svg>
                      </button> */}
                    </div>
                  </div>
                </div>
              </nav>
              <div className="relative overflow-hidden md:mt-6 lg:mt-0">
                <div className="carousel">
                  {Data.map((item, index) => (
                    <div
                      key={index}
                      className={
                        index === curr
                          ? "slide active"
                          : index ===
                              (curr - 1 + Data.length) % Data.length ||
                            (index === (curr + 1) % Data.length && curr !== 0)
                          ? "slide prev"
                          : index === (curr + 1) % Data.length ||
                            (index ===
                              (curr - 1 + Data.length) % Data.length &&
                              curr !== Data.length - 1)
                          ? "slide next"
                          : "slide"
                      }
                    >
                      <img
                        src={item.imag}
                        alt={`Slide ${index}`}
                        style={{ transform: `translateX(-${curr * 100}%)` }}
                        className="w-full h-full md:h-[30vh] lg:h-full"
                      />
                    </div>
                  ))}
                </div>

                <button
                  onClick={prevSlide}
                  className="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                  aria-label="Previous"
                ></button>
                <button
                  onClick={nextSlide}
                  className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                  aria-label="Next"
                ></button>
              </div>
            </div>
          </div>
        </div>
        <FilterModal
          isOpen={isModalOpen}
          onClose={toggleModal}
          brands={brands}
          categories={categories}
          handleBrandSelect={handleBrandSelect}
          handleCategorySelect={handleCategorySelect}
        />
        {showPWAModal && (
          <PWAModal
            show={showPWAModal}
            handlePwaModal={setShowPWAModal}
            handleInstallApp={handleInstallPWA}
          />
        )}
        {isMediumScreen && showPopup && (
          <SearchResultsPopup
            results={result}
            inputRef={inputRef}
            handleSearch={handleOnSearch}
            setSearchQuery={setSearchQuery}
            setShowPopup={setShowPopup}
          />
        )}
      </div>
    </>
  );
};

export default Carousal;