import React, { useState } from "react";
import { FaCreditCard, FaTimes } from "react-icons/fa";
import { connect } from "react-redux";
import Loader from "../Loader/Loader";
import {
  requestPayment,
  receivePaymentSuccess,
  receivePaymentFailure,
  updateBillingAddress,
} from "../../service/actions/actions";
import './PaymentModal.css'

const PaymentModal = ({
  isModalOpen,
  toggleModal,
  requestPayment,
  receivePaymentSuccess,
  receivePaymentFailure,
  updateBillingAddress,
  customerNumber,
  billingAddress,
}) => {
  const [formData, setFormData] = useState({
    cardNumber: "",
    cardHolderName: "",
    cvv: "",
    expiryMonth: "",
    expiryYear: "",
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => {
      let formattedValue = value;

      if (name === "cardNumber") {
        // formattedValue = value.replace(/\D/g, "").slice(0, 16);
        formattedValue = value.replace(/\D/g, "").slice(0, 16).replace(/(.{4})/g, "$1 ").trim();
      } else if (name === "expiryMonth") {
        formattedValue = value.replace(/\D/g, "").slice(0, 2);
        if (formattedValue.length === 1 && parseInt(formattedValue, 10) > 1) {
          formattedValue = "0" + formattedValue;
        }
        const month = parseInt(formattedValue, 10);
        if (month < 1) {
          formattedValue = "01";
        } else if (month > 12) {
          formattedValue = "12";
        }
      } else if (name === "expiryYear") {
        formattedValue = value.replace(/\D/g, "").slice(0, 2);
        const year = parseInt(formattedValue, 10);
        if (year < 1) {
          formattedValue = "01";
        } else if (year > 99) {
          formattedValue = "99";
        }
      } else if (name === "cvv") {
        formattedValue = value.replace(/\D/g, "").slice(0, 3);
      }

      return {
        ...prevFormData,
        [name]: formattedValue,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isEmptyField = Object.values(formData).some((value) => value === "");

    if (isEmptyField) {
      setMessage({ type: "error", text: "Please fill in all fields" });
      return;
    }

    const cardNumberWithoutSpaces = formData.cardNumber.replace(/\s/g, "");

    const cardNumberRegex = /^[0-9]{16}$/;
    if (!cardNumberRegex.test(cardNumberWithoutSpaces)) {
      setMessage({
        type: "error",
        text: "Please enter a valid 16-digit card number",
      });
      return;
    }

    const cvvRegex = /^[0-9]{3}$/;
    if (!cvvRegex.test(formData.cvv)) {
      setMessage({ type: "error", text: "Please enter a valid 3-digit CVV" });
      return;
    }

    const monthRegex = /^(0[1-9]|1[0-2])$/;
    if (!monthRegex.test(formData.expiryMonth)) {
      setMessage({
        type: "error",
        text: "Please enter a valid expiration month (MM)",
      });
      return;
    }

    const yearRegex = /^([0-9]{2})$/;
    if (!yearRegex.test(formData.expiryYear)) {
      setMessage({
        type: "error",
        text: "Please enter a valid expiration year (YY)",
      });
      return;
    }

    setLoading(true);
    updateBillingAddress(formData);
    requestPayment();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        custNum: customerNumber,
        ...formData,
        cardNumber: cardNumberWithoutSpaces,
      }),
    };
    fetch("https://apis.joonbeauty.com:550/addCardDetails", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        receivePaymentSuccess();
        // console.log(data);
        setLoading(false);
        setMessage({ type: "success", text: "Payment successful!" });
        setTimeout(() => {
          setMessage(null);
        }, 3000);
        setTimeout(() => {
          toggleModal();
        }, 3000);
      })
      .catch((error) => {
        setLoading(false);
        setMessage({
          type: "error",
          text: "Payment failed. Please try again.",
        });
        setTimeout(() => {
          setMessage(null);
        }, 3000);
        receivePaymentFailure(error);
        console.error(error);
      });
  };

  return (
    <div>
      {isModalOpen && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          <div className="bg-white w-96 rounded-lg overflow-hidden relative">
            <div className="p-6">
              {loading && <Loader />}
              <h2 className="text-xl font-semibold mb-4 text-center">
                Payment Methods
              </h2>
              <button
                className="absolute top-0 right-0 m-3 text-gray-500 hover:text-gray-700"
                onClick={toggleModal}
              >
                <FaTimes size={20} />
              </button>
              {message && (
                <div
                  className={`text-center mb-4 text-${
                    message.type === "error" ? "red" : "green"
                  }-600`}
                >
                  {message.text}
                </div>
              )}
              <div>
                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <label
                      htmlFor="cardNumber"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Credit Card Number
                    </label>
                    <div className="relative">
                      <input
                        id="cardNumber"
                        type="text"
                        placeholder="0000 0000 0000 0000"
                        className="w-full border rounded bgclr py-2 px-3 focus:outline-none focus:border-blue-500"
                        name="cardNumber"
                        value={formData.cardNumber}
                        onChange={handleInputChange}
                      />
                      <div className="absolute top-1/2 text-white right-3 transform -translate-y-1/2">
                        <FaCreditCard size={24} />
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="cardHolderName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Card Holder Name
                    </label>
                    <input
                      id="cardHolderName"
                      type="text"
                      placeholder="Enter card holder name"
                      className="w-full border rounded py-2 px-3 bgclr focus:outline-none focus:border-blue-500"
                      name="cardHolderName"
                      value={formData.cardHolderName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex mb-4">
                    <div className="w-1/3 mr-2">
                      <label
                        htmlFor="creditCardCVV"
                        className="block text-sm font-medium text-gray-700"
                      >
                        CVV
                      </label>
                      <input
                        id="creditCardCVV"
                        type="text"
                        placeholder="CVV"
                        className="w-full border rounded py-2 px-3 bgclr focus:outline-none focus:border-blue-500"
                        name="cvv"
                        value={formData.cvv}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="w-1/3 mr-2">
                      <label
                        htmlFor="creditCardExpiryMonth"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Exp Month
                      </label>
                      <input
                        id="creditCardExpiryMonth"
                        type="text"
                        placeholder="MM"
                        className="w-full border rounded py-2 px-3 bgclr focus:outline-none focus:border-blue-500"
                        name="expiryMonth"
                        value={formData.expiryMonth}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="w-1/3">
                      <label
                        htmlFor="creditCardExpiryYear"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Exp Year
                      </label>
                      <input
                        id="creditCardExpiryYear"
                        type="text"
                        placeholder="YY"
                        className="w-full border rounded py-2 px-3 bgclr focus:outline-none focus:border-blue-500"
                        name="expiryYear"
                        value={formData.expiryYear}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 w-full"
                  >
                    Save & Continue
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  requestPayment: () => dispatch(requestPayment()),
  receivePaymentSuccess: () => dispatch(receivePaymentSuccess()),
  receivePaymentFailure: (error) => dispatch(receivePaymentFailure(error)),
  updateBillingAddress: (billingAddress) =>
    dispatch(updateBillingAddress(billingAddress)),
});

export default connect(null, mapDispatchToProps)(PaymentModal);