import React from "react";
import { ToastContainer } from "react-toastify";
import { connect } from "react-redux";
import { addToCart } from "../../service/actions/actions";
import noimage from "../../Assets/noimage1.png";
import Loader from "../../Components/Loader/Loader";
import "./search.css";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfiniteScroll from "react-infinite-scroll-component";
import Carousal from "../../Components/Slider/Carousal";
const SearchResultItem = ({
  searchResults,
  addToCartHandler,
  loading,
  searchQuery,
  handleChange,
  handleSearch,
  search,
  handleInfiniteScroll,
  result,
  showPopup,
  setShowPopup,
  setSearchQuery,
}) => {
  const BASE_IMAGE_URL = "https://apis.joonbeauty.com:550";

  // console.log("Items that are being searched: ", searchResults);
  //console.log("Searched Products: ", products)

  return (
    <div className="container m-auto">
      <ToastContainer />

      <div className="md:pt-14 pt-0">
        <Carousal
          bgColor="#f0f0f0"
          padding="md:pt-0"
          searchQuery={searchQuery}
          handleChange={handleChange}
          handleSearch={handleSearch}
          result={result}
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          setSearchQuery={setSearchQuery}
        />
      </div>
      <h1 className="text-2xl md:pt-12 px-2 sm:px-0 md:ps-3 pt-3 ">
        Search Results
      </h1>
      <InfiniteScroll
        dataLength={search.length}
        next={handleInfiniteScroll}
        hasMore={true}
        //loader={<Loader />}
        //className="mt-9 grid grid-cols-2 lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-2 gap-4 mx-2 sm:mx-0 md:mx-3 "
      >
        <div className="mt-9 grid grid-cols-2 lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-2 gap-4 mx-2 sm:mx-0 md:mx-3 mb-16">
          {loading && <Loader />}
          {search.length <= 0 && !loading ? (
            <div className=" justify-center items-center grid col-span-full h-full">
              <FontAwesomeIcon
                className="px-3 md:w-[40vh] md:h-[60vh] w-[30vh] h-[40vh] flex justify-center items-center md:ms-[4vh] sm:ms-[10vh] ms-[3vh] bg-color-two "
                icon={faCircleExclamation}
              />
              <h1 className="text-center text-xl md:text-[7vh] sm:text-[10vh] text-[5vh]">
                No Product Found
              </h1>
            </div>
          ) : (
            search.map((item) => (
              <div
                key={item.itemNum}
                className="max-w-sm bg-white border border-gray-200 rounded-lg shadow flex flex-col items-center justify-center"
              >
                <a href="img">
                  {item.imageFilename ? (
                    <img
                      className="rounded-t-lg h-48 lg:h-48 object-cover"
                      src={`${BASE_IMAGE_URL}${item.imageFilename}`}
                      alt=""
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = noimage;
                      }}
                    />
                  ) : (
                    <img
                      className="rounded-t-lg h-48 lg:h-48 object-cover"
                      src={noimage}
                      alt="Not found"
                    />
                  )}
                </a>
                <div className="p-5 flex flex-col flex-grow">
                  <div>
                    <p className="font-normal line-clamp-3 text-black">
                      {item.itemName}
                    </p>
                  </div>
                  <div className="mt-3">
                    <p className="font-normal text-left text-red-900">
                      ${item.price}
                    </p>
                  </div>
                  <div className="mt-auto flex justify-center">
                    <button
                      type="button"
                      className="border px-6 mt-2 bg-red-900 text-white py-2 rounded text-sm h-10 min-w-[8rem]"
                      onClick={() =>
                        addToCartHandler({
                          price: item.price,
                          name: item.itemName,
                          id: item.itemNum,
                          image: item.imageFilename,
                        })
                      }
                    >
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </InfiniteScroll>
    </div>
  );
};

const mapStateToProps = (state) => ({
  searchResults: state.searchReducer.searchResults,
});

const mapDispatchToProps = (dispatch) => ({
  addToCartHandler: (data) => dispatch(addToCart(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultItem);
