export const ADD_TO_CART = "ADD_TO_CART";

export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';

export const INCREASE_QUANTITY = 'INCREASE_QUANTITY';

export const DECREASE_QUANTITY = 'DECREASE_QUANTITY';

export const UPDATE_QUANTITY = 'UPDATE_QUANTITY';

export const CLEAR_CART = 'CLEAR_CART';

export const SET_HALF_BOX_QUANTITY = 'SET_HALF_BOX_QUANTITY';

export const SET_FULL_BOX_QUANTITY = 'SET_FULL_BOX_QUANTITY';

export const RESET_QUANTITY = "RESET_QUANTITY";

export const SET_QUANTITY_TYPE = 'SET_QUANTITY_TYPE';

export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";

export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";

export const SEARCH_REQUEST = 'SEARCH_REQUEST';

export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';

export const SEARCH_FAILURE = 'SEARCH_FAILURE';

export const FETCH_CATEGORIES_REQUEST = 'FETCH_CATEGORIES_REQUEST';

export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';

export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT = "LOGOUT";

export const REQUEST_PAYMENT = 'REQUEST_PAYMENT';

export const RECEIVE_PAYMENT_SUCCESS = 'RECEIVE_PAYMENT_SUCCESS';

export const RECEIVE_PAYMENT_FAILURE = 'RECEIVE_PAYMENT_FAILURE';

export const SET_PAYMENT_DETAILS_ADDED = 'SET_PAYMENT_DETAILS_ADDED';

export const UPDATE_BILLING_ADDRESS = 'UPDATE_BILLING_ADDRESS';

export const STORE_USER_DATA = 'STORE_USER_DATA';

// export const UPDATE_DELIVERY_ADDRESS = 'UPDATE_DELIVERY_ADDRESS';

export const CLEAR_BILLING_ADDRESS = 'CLEAR_BILLING_ADDRESS';

// constants.js

export const REQUEST_UPDATE_DELIVERY_ADDRESS = 'REQUEST_UPDATE_DELIVERY_ADDRESS';
export const UPDATE_DELIVERY_ADDRESS_SUCCESS = 'UPDATE_DELIVERY_ADDRESS_SUCCESS';
export const UPDATE_DELIVERY_ADDRESS_FAILURE = 'UPDATE_DELIVERY_ADDRESS_FAILURE';



export const REQUEST_ADDRESS = 'REQUEST_ADDRESS';
export const RECEIVE_ADDRESS_SUCCESS = 'RECEIVE_ADDRESS_SUCCESS';
export const RECEIVE_ADDRESS_FAILURE = 'RECEIVE_ADDRESS_FAILURE';
export const UPDATE_DELIVERY_ADDRESS = 'UPDATE_DELIVERY_ADDRESS';

//Orders
export const FETCH_ORDERS_REQUEST = 'FETCH_ORDERS_REQUEST';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAILURE = 'FETCH_ORDERS_FAILURE';
export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FETCH_ORDER_DETAILS_REQUEST = 'FETCH_ORDER_DETAILS_REQUEST';
export const FETCH_ORDER_DETAILS_SUCCESS = 'FETCH_ORDER_DETAILS_SUCCESS';
export const FETCH_ORDER_DETAILS_FAILURE = 'FETCH_ORDER_DETAILS_FAILURE';