import React, { useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import { Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import Navbar from "./Components/Navbar/Navbar";
import Footerbar from "./Components/FooterBar/Footerbar";
import Home from "./Pages/Home/Home";
// import CartPage from "./Components/CartPage/CartPage";
import Orders from "./Components/Orders/Orders";
import Account from "./Components/account/account";
import Categories from "./Pages/Home/Categories";
import SearchResultItem from "./Components/SearchResults/SearchResults";
import DetailCart from "./Pages/Home/DetailCart";
import ProductSearch from "./Components/ProductSearch/ProductSearch";
import SearchBar from "./Components/SearchBar/SearchBar";
import Carousal from "./Components/Slider/Carousal";
// import Catalog from "./Components/Catalog/Catalog";
import Cart from "./Components/Cart/Cart";
import {
  searchRequest,
  searchSuccess,
  searchFailure,
  loginSuccess,
} from "./service/actions/actions";
import BrandsPage from "./Pages/BrandsPage";

const App = ({
  searchRequest,
  searchSuccess,
  searchFailure,
  searchResults,
  loginSuccess,
  logout,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState([]);
  const [result, setResult] = useState([]);
  const [nextPage, setNextPage] = useState(1);
  const [showPopup, setShowPopup] = useState(false);
  // const [timer, setTimer] = useState(null);
  // const [searchTerm, setSearchTerm] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    const userData = localStorage.getItem("user");
    if (userData) {
      const user = JSON.parse(userData);
      loginSuccess(user);
    }
  }, [loginSuccess]);

  useEffect(() => {
    console.log("Selected Brand inn app:", selectedBrand);
  }, [selectedBrand]);

  const handleDoneClick = async (
    searchQuery,
    selectedBrand,
    selectedCategory
  ) => {
    setNextPage(1);
    searchRequest();
    try {
      // setLoading(true);
      const response = await fetch(
        `https://apis.joonbeauty.com:550/getItemByName`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            brand: {
              itemNum: /^\d+$/.test(searchQuery) ? searchQuery : "",
              itemName: /^\d+$/.test(searchQuery) ? "" : searchQuery,
              itemBrand: selectedBrand,
            },
            pagination: {
              page: nextPage,
              perPage: 5,
            },
            category: selectedCategory,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      if (responseData.success) {
        const data = responseData.data;
        console.log("Searching: ", data);
        console.log("current data: ", search);
        if (Array.isArray(data) && data.length > 0) {
          setSearch((prevSearch) => [...prevSearch, ...data]);
          // setNextPage(nextPage + 1);
          console.log("Search Results :", data);
          searchSuccess(data);
          setLoading(false);
          // setShowPopup(false);
          setResult(data);
        } else if (searchResults.length === 0) {
          setLoading(false);
          setHasMore(false);
          console.log("items not found", searchResults);
        }
      } else {
        searchFailure(responseData.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      searchFailure(error.message);
      setLoading(false);
    }
  };

  const debouncedSearch = useCallback(
    debounce((searchQuery1, searchQuery, selectedBrand, selectedCategory) => {
      handleDoneClick(
        searchQuery1,
        searchQuery,
        selectedBrand,
        selectedCategory
      );
    }, 300),
    []
  );

  const handleChange = (event) => {
    const newQuery = event.target.value;
    setSearchQuery(newQuery);
    console.log("Selects :", selectedBrand);
    setResult([]);
    // clearTimeout(timer);
    if (newQuery.trim() === "") {
      setShowPopup(false);
      return;
    }

    // setSearchTerm(newQuery);
    // debouncedSearch(newQuery);
    debouncedSearch(newQuery, selectedBrand, selectedCategory);

    if (newQuery.trim() === "") {
      setShowPopup(false);
    } else {
      setShowPopup(true);
    }
  };

  const handleOnChange = (event) => {
    const newQuery = event.target.value;
    setSearchQuery1(newQuery);
    setResult([]);
    console.log("BrandedITem: ", selectedBrand);
    console.log("Selects :", selectedBrand);
    debouncedSearch(newQuery, selectedBrand, selectedCategory);
    // clearTimeout(timer);
    if (newQuery.trim() === "") {
      setShowPopup(false);
      return;
    }

    // setSearchTerm(newQuery);
    // debouncedSearch(newQuery);
    debouncedSearch(newQuery, selectedBrand, selectedCategory);

    if (newQuery.trim() === "") {
      setShowPopup(false);
    } else {
    }
  };

  const handleFetchMoreResults = useCallback(async () => {
    if (loading || !hasMore) return;
    searchRequest();
    try {
      const response = await fetch(
        `https://apis.joonbeauty.com:550/getItemByName`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            brand: {
              itemName: searchQuery || searchQuery1,
              itemBrand: selectedBrand,
            },
            pagination: {
              page: nextPage,
              perPage: 5,
            },
            category: selectedCategory,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      if (responseData.success) {
        const data = responseData.data;
        if (Array.isArray(data) && data.length > 0) {
          setSearch((prevSearch) => [...prevSearch, ...data]);
          setNextPage((nextPage) => nextPage + 1);
          setResult((prevResult) => [...prevResult, ...data]);
        } else {
          setHasMore(false);
        }
      } else {
        searchFailure(responseData.message);
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      searchFailure(error.message);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  }, [searchQuery, searchQuery1, nextPage, searchRequest, searchFailure, selectedBrand, selectedCategory, hasMore, loading]);

  const handleInfiniteScroll = useCallback(() => {
    handleFetchMoreResults();
  }, [handleFetchMoreResults]);

  return (
    <div>
      <Navbar
        handleSearch={handleDoneClick}
        handleChange={handleChange}
        loading={loading}
        setLoading={setLoading}
        searchQuery={searchQuery}
        result={result}
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        setSearchQuery={setSearchQuery}
        selectedBrand={selectedBrand}
        setSelectedBrand={setSelectedBrand}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
      <Routes>
        <Route
          path="/"
          element={
            <Home
              handleDoneClick={handleDoneClick}
              handleChange={handleChange}
              searchQuery={searchQuery}
              searchQuery1={searchQuery1}
              loading={loading}
              setLoading={setLoading}
              handleInfiniteScroll={handleInfiniteScroll}
              search={search}
              result={result}
              setResult={setResult}
              showPopup={showPopup}
              setShowPopup={setShowPopup}
              setSearchQuery={setSearchQuery}
              setSearchQuery1={setSearchQuery1}
              selectedBrand={selectedBrand}
              setSelectedBrand={setSelectedBrand}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              handleOnChange={handleOnChange}
              hasMore={hasMore}
              setHasMore={setHasMore}
            />
          }
        />
        <Route
          path="/quickshop"
          element={
            <ProductSearch
              handleSearch={handleDoneClick}
              handleChange={handleChange}
            />
          }
        />
        <Route
          path="/searchbar"
          element={
            <SearchBar
              setShowPopup={setShowPopup}
              result={result}
              showPopup={showPopup}
              setSearchQuery={setSearchQuery}
              handleSearch={handleDoneClick}
              handleChange={handleChange}
            />
          }
        />
        <Route
          path="/carousal"
          element={
            <Carousal
              setShowPopup={setShowPopup}
              result={result}
              showPopup={showPopup}
              setSearchQuery={setSearchQuery}
              handleSearch={handleDoneClick}
              handleChange={handleChange}
            />
          }
        />
        <Route
          path="/cart"
          element={
            <Cart
              handleSearch={handleDoneClick}
              handleChange={handleChange}
              searchQuery={searchQuery}
              result={result}
              setResult={setResult}
              showPopup={showPopup}
              setShowPopup={setShowPopup}
              setSearchQuery={setSearchQuery}
              setLoading={setLoading}
              selectedBrand={selectedBrand}
              setSelectedBrand={setSelectedBrand}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
          }
        />
        <Route path="/orders" element={<Orders />} />
        <Route path="/account" element={<Account />} />
        <Route path="/brands/:itemBrand" element={<BrandsPage />} />
        <Route
          path="/search"
          element={
            <SearchResultItem
              loading={loading}
              searchQuery={searchQuery}
              handleInfiniteScroll={handleInfiniteScroll}
              handleSearch={handleDoneClick}
              handleChange={handleChange}
              search={search}
              result={result}
              showPopup={showPopup}
              setShowPopup={setShowPopup}
              setSearchQuery={setSearchQuery}
            />
          }
        />
        <Route path="/DetailCart" element={<DetailCart />} />
        <Route
          path="/category/:categoryName"
          element={
            <Categories
              searchQuery={searchQuery}
              handleSearch={handleDoneClick}
              handleChange={handleChange}
            />
          }
        />
      </Routes>
      <Footerbar />
    </div>
  );
};

const mapStateToProps = (state) => ({
  searchResults: state.searchReducer.searchResults,
});

export default connect(mapStateToProps, {
  searchRequest,
  searchSuccess,
  searchFailure,
  loginSuccess,
})(App);
