import { combineReducers } from "redux";
import cardItems from "./reducer";
import productsReducer from "./productsReducer";
import searchReducer from "./searchReducer";
import categoriesReducer from "./categoriesReducer";
import authReducer from './authReducer';
import paymentReducer from './paymentreducer';
import AddressReducer from "./AddressReducer";
import ordersReducer from "./ordersReducer";

export default combineReducers({
  cardItems,
  productsReducer,
  searchReducer,
  categories: categoriesReducer,
  auth: authReducer,
  payment: paymentReducer,
  address: AddressReducer,
  orders : ordersReducer
});