import React from "react";
import "./styles.css";

const CheckboxSection = ({
  categories,
  onCategoryChange,
  loading,
  checkCategory,
  setCheckCategory,
}) => {

  const handleCheckboxChange = (selectedCategory) => {
    setCheckCategory(selectedCategory);
    onCategoryChange(selectedCategory);
  };

  return (
    <div className="w-full p-1">
      <div className="grid grid-cols-4 xs:grid-cols-6 lg:grid-cols-8 xl:grid-cols-10 2xl:grid-cols-12 md:grid-cols-6 lg:gap-2 gap-1">
        {categories.map((category) => (
          <div
            key={category.categoryName}
            className="flex items-center justify-center h-full"
          >
            <button
              className={`focus:outline-none flex items-center text-xs justify-center rounded-lg w-20 lg:w-32 md:w-20 px-4 py-1 ${
                checkCategory === category.categoryName
                  ? 'bg-gray-800 text-white border-gray-400'
                  : 'bg-gray-200 text-gray-800'
              }`}
              onClick={() => handleCheckboxChange(category.categoryName)}
              disabled={loading}
            >
              {category.categoryName}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CheckboxSection;