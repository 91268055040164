import React, { useState, useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import "./styles.css";

const ButtonsSection = ({
  categories,
  setSelectedBrand,
  fetchProducts,
  // onCategoryChange,
  setSearchQuery,
  selectedBrand,
  setBestSeller,
  bestSeller
}) => {
  const [itemBrands, setItemBrands] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showAllBrands, setShowAllBrands] = useState(false);

  useEffect(() => {
    if (!categories) {
      setItemBrands([]);
      return;
    }

    const fetchItemBrands = async () => {
      setIsLoading(true);

      try {
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(categories),
        };

        const response = await fetch(
          `https://apis.joonbeauty.com:550/GetDistinctItemBrands`,
          requestOptions
        );

        if (!response.ok) {
          throw new Error("Failed to fetch item brands");
        }

        const data = await response.json();
        setItemBrands(data.data);
        console.log("Data from API:", data);
      } catch (error) {
        console.error("Error fetching item brands:", error);
        setError(error.message || "Failed to fetch item brands");
      } finally {
        setIsLoading(false);
      }
    };

    fetchItemBrands();
  }, [categories]);

  const allProductsClick = () => {
    setSelectedBrand("");
    setSearchQuery("");
    setBestSeller(""); 
    fetchProducts("", "", "", "", "");
  };
  

  const toggleShowAllBrands = () => {
    setShowAllBrands(!showAllBrands);
  };

  // const handleBestSellerClick = () => {
  //   setBestSeller("bestSeller");
  //   console.log("Best Seller button clicked");
  // };
  

  return (
    <div className="mt-5 w-full border-b-2 border-gray-lighter overflow-x-auto scrollbar-thin">
      <div className="flex flex-wrap justify-center items-center lg:items-center lg:justify-start">
        {/* Render "All Products" button */}
        <div
          className={`pb-4 px-2 group border-black ${
            selectedBrand === null,
            setBestSeller === null
              ? "border-green-900"
              : "hover:border-b focus:border-green-900"
          }`}
        >
          <button
            onClick={() => {
              allProductsClick();
              setSelectedBrand("");
            }}
            className={`flex items-center custom-label justify-center rounded-xl border w-24 md:w-32 lg:w-32 h-10 p-0 ${
              selectedBrand === null,
              setBestSeller === null

                ? "bg-black text-white"
                : "bg-white border-black text-black group-hover:bg-black group-hover:text-white focus:outline-none"
            }`}
          >
            All Products
          </button>
        </div>

        {/* Render "Best Seller" button */}
        {/* <div
          className={`pb-4 px-2 group border-black ${
            bestSeller === "bestSeller"
              ? "border-green-900"
              : "hover:border-b focus:border-green-900"
          }`}
        >
          <button
            onClick={() => {
              handleBestSellerClick();
            }}
            className={`flex items-center custom-label justify-center rounded-xl border w-24 md:w-32 lg:w-32 h-10 p-0 ${
              selectedBrand === "bestSeller"
                ? "bg-black text-white"
                : "bg-white border-black text-black group-hover:bg-black group-hover:text-white focus:outline-none"
            }`}
          >
            Best Seller
          </button>
        </div> */}

        {/* Render first brand */}
        {itemBrands.length > 0 && (
          <div
            key={0}
            className={`pb-4 px-2 group border-black ${
              selectedBrand === itemBrands[0]
                ? "border-green-900"
                : "hover:border-b focus:border-green-900"
            }`}
          >
            <button
              onClick={() => setSelectedBrand(itemBrands[0])}
              className={`flex items-center custom-label justify-center rounded-xl border w-24 md:w-32 lg:w-32 h-10 p-0 ${
                selectedBrand === itemBrands[0]
                  ? "bg-black text-white"
                  : "bg-white border-black text-black group-hover:bg-black group-hover:text-white focus:outline-none"
              }`}
            >
              {itemBrands[0]}
            </button>
          </div>
        )}

        {/* Show button to toggle displaying all brands */}
        {!showAllBrands && itemBrands.length > 1 && (
          <div className="pb-4 px-2 group border-black">
            <button
              onClick={toggleShowAllBrands}
              className="flex items-center custom-label justify-center rounded-xl border w-24 md:w-32 lg:w-32 h-10 p-0 bg-transparent border-black text-black group-hover:bg-black group-hover:text-white focus:outline-none"
            >
              Show All
            </button>
          </div>
        )}

        {/* Render remaining brands if showAllBrands is true */}
        {showAllBrands &&
          itemBrands.slice(1).map((brand, index) => (
            <div
              key={index + 1}
              className={`pb-4 px-2 group border-black ${
                selectedBrand === brand
                  ? "border-green-900"
                  : "hover:border-b focus:border-green-900"
              }`}
            >
              <button
                onClick={() => setSelectedBrand(brand)}
                className={`flex items-center custom-label justify-center rounded-xl border w-24 md:w-32 lg:w-32 h-10 p-0 ${
                  selectedBrand === brand 
                    ? "bg-black text-white"
                    : "bg-white border-black text-black group-hover:bg-black group-hover:text-white focus:outline-none"
                }`}
              >
                {brand}
              </button>
            </div>
          ))}

        {/* Show button to toggle hiding extra brands */}
        {showAllBrands && itemBrands.length > 1 && (
          <div className="pb-4 px-2 group border-black">
            <button
              onClick={toggleShowAllBrands}
              className="flex items-center custom-label justify-center rounded-xl border w-24 md:w-32 lg:w-32 h-10 p-0 bg-transparent border-black text-black group-hover:bg-black group-hover:text-white focus:outline-none"
            >
              Show Less
            </button>
          </div>
        )}
      </div>

      {/* Render loading or error message */}
      {isLoading || error ? (
        <div className="flex items-center justify-center flex-1">
          {isLoading ? (
            <ThreeDots color="#000" height={50} width={50} timeout={1000} />
          ) : (
            <p className="text-center">Error: {error}</p>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default ButtonsSection;
