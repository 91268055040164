import { SEARCH_REQUEST, SEARCH_SUCCESS, SEARCH_FAILURE } from "../constants";

const initialState = {
  searchQuery: '',
  searchResults: [],
  loading: false,
  error: null,
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        searchResults: action.payload,
        loading: false,
        
      };
    case SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default searchReducer;
