import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { addToCart } from "../../service/actions/actions";
import noimage from "../../Assets/noimage1.png";
import { ToastContainer } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../Components/Loader/Loader";
import Carousal from "../../Components/Slider/Carousal";
import "./cate.css";

const CateSidebar = ({
  addToCartHandler,
  searchQuery,
  handleChange,
  handleSearch,
}) => {
  const { categoryName } = useParams();
  const [categoriesData, setCategoriesData] = useState([]);
  const [error, setError] = useState(null);
  const [nextPage, setNextPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const BASE_IMAGE_URL = "https://apis.joonbeauty.com:550";

  useEffect(() => {
    setCategoriesData([]);
    setNextPage(1);
    setError(null);
    fetchItemsByCategory();
  }, [categoryName]);

  const fetchItemsByCategory = async () => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          CategoryName: categoryName,
          page: nextPage,
          perPage: 12,
        }),
      };
      setIsLoading(true);
      const response = await fetch(
        "https://apis.joonbeauty.com:550/GetItemsByCategory",
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        setCategoriesData((prevData) => [...prevData, ...data.data]);
        setNextPage((prevPage) => prevPage + 1);
      } else {
        setError(data.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching items by category:", error);
      setError("Error retrieving items by category. Please try again later.");
      setIsLoading(false);
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container m-auto">
      <ToastContainer />
      <Carousal
        bgColor="#f0f0f0"
        padding="md:pt-0"
        searchQuery={searchQuery}
        handleChange={handleChange}
        handleSearch={handleSearch}
      />
      <div className="mt-9 ps-2 sm:ps-20 text-gray-700">
        <p className="text-2xl md:pt-5 sm:px-0 md:ps-3 pt-3">
          HOME / {categoryName}
        </p>
      </div>
      {isLoading && (
        <div className="w-full h-1 bg-gray-200 rounded overflow-hidden mt-2">
          <div className="h-full bg-blue-500" style={{ width: "0%" }}></div>
        </div>
      )}
      <div className="container m-auto">
        {isLoading && <Loader />}
        {categoriesData.length > 0 && (
          <InfiniteScroll
            dataLength={categoriesData.length}
            next={fetchItemsByCategory}
            hasMore={true}
            loader={<Loader />}
            className="mt-9 grid grid-cols-2 lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-2 gap-4 mx-2 sm:mx-0 md:mx-3"
          >
            {categoriesData.map((item) => (
              <div
                key={item.itemNum}
                className="bg-white border border-gray-200 rounded-lg shadow flex flex-col overflow-hidden"
              >
                <a href={`/category/${item.itemNum}`}>
                  <div className="flex justify-center overflow-hidden">
                  {item.imageFilename ? (
                      <img
                        className="rounded-t-lg object-contain w-full h-48 lg:h-48 transform transition-transform hover:scale-105"
                        src={`${BASE_IMAGE_URL}${item.imageFilename}`}
                        alt=""
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = noimage;
                        }}
                      />
                    ) : (
                      <img
                        className="rounded-t-lg object-contain w-full h-48 lg:h-48 transform transition-transform hover:scale-105"
                        src={noimage}
                        alt="Not found"
                      />
                    )}
                  </div>
                </a>
                <div className="p-3 flex flex-col h-full">
                  <div className="overflow-hidden">
                    <p className="font-normal text-black overflow-hidden whitespace-nowrap truncate">
                      {item.itemName.charAt(0).toUpperCase() + item.itemName.slice(1)}
                    </p>
                  </div>
                  <div>
                    <p className="font-normal text-left text-red-900">
                      ${item.price}
                    </p>
                  </div>
                  <div className="mt-auto">
                    <button
                      type="button"
                      className="border px-6 md:px-8 mt-2 bg-red-900 hover:text-white text-white py-2 rounded text-sm lg:text-xs min-w-[8rem]"
                      onClick={() =>
                        addToCartHandler({
                          price: item.price,
                          name: item.itemName,
                          id: item.itemNum,
                          image: item.imageFilename,
                        })
                      }
                    >
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addToCartHandler: (data) => dispatch(addToCart(data)),
});

export default connect(null, mapDispatchToProps)(CateSidebar);