import { format } from "date-fns";
import React, { useState, useEffect } from "react";
import './Orders.css';

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [customerData] = useState({
    customerNumber: "1304",
    phoneNumber: "214-392-2775",
  });

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("accept", "*/*");
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify(customerData);
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
        const response = await fetch(
          "https://apis.joonbeauty.com:550/orders",
          requestOptions
        );
        const data = await response.json();
        if (response.ok) {
          // console.log("Fetched orders data:", data);
          if (data.success) {
            setOrders(data.data);
            // console.log(data.data);
          } else {
            console.error("Error fetching orders data:", data.message);
          }
        } else {
          throw new Error("Network response was not ok");
        }
      } catch (error) {
        console.error("Error fetching orders data:", error);
      }
    };
    fetchOrders();
  }, [customerData]);

  return (
    <div className="p-5 mt-20 w-4/5 mx-auto order-page">
      <h2 className="text-3xl mb-4 name-text">My Orders</h2>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {orders.map((order, index) => (
          <div key={index} className="bg-white p-4 shadow-md rounded-md name-text">
            <div className="flex justify-between items-center">
              <div>
                {order.odrStatus === 4 ? (
                  <span>
                    Order <span className="text-green-600">Delivered</span>
                  </span>
                ) : order.odrStatus === 1 ? (
                  <span>
                    Order <span className="text-blue-600">In Progress</span>
                  </span>
                ) : order.odrStatus === 0 ? (
                  <span>
                    Order <span className="text-red-600">Cancelled</span>
                  </span>
                ) : (
                  "Status Unknown"
                )}
              </div>
              <div className="text-green-600">${order.grandTotal}</div>
            </div>
            <div className="flex justify-between items-center">
            <div className="text-sm text-gray-600">
              {format(new Date(order.orderDate), "yyyy-MM-dd")}
            </div>
            <div>
              {order.odrStatus === 4 ? (
                <span className="text-green-600">Cash succeeded</span>
              ) : order.odrStatus === 1 ? (
                <span className="text-blue-600">Order in progress</span>
              ) : order.odrStatus === 0 ? (
                <span className="text-red-600">Order Cancelled</span>
              ) : (
                "Payment status unknown"
              )}
            </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Orders;
