import React, { useState } from "react";
import { connect } from "react-redux";
import noimage from "../../Assets/noimage1.png";
import mastercard from "../../Assets/icons/mastercard.png";
import visa from "../../Assets/icons/visa.png";
import american_express from "../../Assets/icons/american_express.png";
import maestro from "../../Assets/icons/maestro.png";
import ideal from "../../Assets/icons/ideal.png";
// import klarna from "../../Assets/icons/klarna.png";
import bank from "../../Assets/icons/bank.png";
import fedex from "../../Assets/icons/fedex.png";
import ups from "../../Assets/icons/ups.png";
import postnl from "../../Assets/icons/postnl.png";
import dhl from "../../Assets/icons/dhl.png";
import Loader from "../Loader/Loader";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  removeFromCart,
  increaseQuantity,
  decreaseQuantity,
  clearCart,
  setHalfBoxQuantity,
  setFullBoxQuantity,
  resetQuantity,
  setQuantityType,
} from "../../service/actions/actions";
import Header from "../Header/Header";
import SearchBar from "../SearchBar/SearchBar";
import PaymentModal from "../PaymentModal/PaymentModal";
import Modal from "../Modal/Modal";
import "./Cart.css";

const Cart = ({
  cartItems,
  removeFromCart,
  increaseQuantity,
  decreaseQuantity,
  clearCart,
  setHalfBoxQuantity,
  setFullBoxQuantity,
  resetQuantity,
  setQuantityType,
  billingAddress,
  isLoggedIn,
  customerNumber,
  searchQuery,
  handleChange,
  handleSearch,
  result,
  showPopup,
  setShowPopup,
  setSearchQuery,
  setLoading,
  selectedBrand,
  setSelectedBrand,
  selectedCategory,
  setSelectedCategory,
}) => {
  const [showCartMessage, setShowCartMessage] = useState(true);
  const BASE_IMAGE_URL = "https://apis.joonbeauty.com:550/";
  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [successMessage, setSuccessMessage] = useState("");
  const [notes, setNotes] = useState("");
  const navigate = useNavigate();

  const subtotal = cartItems.reduce((total, item) => {
    return total + item.price * (item.quantity || 1);
  }, 0);

  const handleRemoveItem = (id) => {
    removeFromCart(id);
  };

  const handleIncreaseQuantity = (id) => {
    increaseQuantity(id);
  };

  const handleDecreaseQuantity = (id) => {
    decreaseQuantity(id);
  };

  // const handleSetHalfBoxQuantity = (id) => {
  //   setHalfBoxQuantity(id);
  // };

  // const handleSetFullBoxQuantity = (id) => {
  //   setFullBoxQuantity(id);
  // };

  const handleSetQuantityType = (id, quantityType) => {
    setQuantityType(id, quantityType);
  };

  const handleClearQuantity = (id) => {
    resetQuantity(id);
  };

  const BOX_SIZE = 72;

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      console.error("Failed to parse JWT", e);
      return null;
    }
  };

  const handleCheckout = async () => {
    console.log("Checkout button clicked");

    const jwtToken = localStorage.getItem("jwtToken");
    if (jwtToken) {
      const decodedToken = parseJwt(jwtToken);
      if (decodedToken && decodedToken.exp * 1000 < Date.now()) {
        console.log("Token expired, opening login modal");
        setLoginModalOpen(true);
        return;
      }
    }

    if (!isLoggedIn) {
      console.log("User not logged in, opening login modal");
      setLoginModalOpen(true);
      return;
    }

    setIsLoading(true);

    const orderItems = cartItems.map((item) => ({
      itemNum: item.id,
      quantity: item.quantity,
      price: item.price,
    }));

    const requestBody = {
      custNum: customerNumber,
      cartItems: orderItems,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
      body: JSON.stringify(requestBody),
      redirect: "follow",
    };

    try {
      const response = await fetch(
        "https://apis.joonbeauty.com:550/OrderItems",
        requestOptions
      );
      //const responseData = await response.json();
      setIsLoading(false);

      if (response.ok) {
        toast.success("Order placed successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          navigate("/");
        }, 3000);
        clearCart();
      } else {
        throw new Error("Failed to place order. Please try again.");
      }
    } catch (error) {
      console.error(error);
      toast.error(
        "An error occurred while placing the order. Please try again later.",
        {
          position: "top-right",
        }
      );
    }
  };

  const toggleCartMessage = () => {
    setShowCartMessage(!showCartMessage);
  };
  return (
    <div>
      <ToastContainer />
      <div className="lg:mb-10 :mb-10">
        <Header />
      </div>
      <div className="relative lg:mt-20 md:mt-12 flex flex-col items-center justify-center">
        <div className="relative flex items-center mt-3">
          {cartItems.length > 0 && (
            <SearchBar
              searchQuery={searchQuery}
              handleChange={handleChange}
              handleSearch={handleSearch}
              result={result}
              showPopup={showPopup}
              setShowPopup={setShowPopup}
              setSearchQuery={setSearchQuery}
              setLoading={setLoading}
              selectedBrand={selectedBrand}
              setSelectedBrand={setSelectedBrand}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
          )}
        </div>
      </div>
      <div className="container mx-auto px-4 py-8 mt-5">
        <h1 className="font-sans text-3xl text-center mb-1 font-sans w1">
          Cart
        </h1>
        <h4 className="text-center mb-8 text-gray-500 lg:mx-auto font-normal text-gray-dark w4 text-xs">
          Review your order details
        </h4>
        <div className="flex justify-between items-center border-b-2 border-gray-light mb-4 mt-10">
          <div className="border-b-2 pb-6 border-gray-700">
            <button className="flex items-center justify-center text-xs rounded-full bg-white shadow-lg w-72 h-10 ">
              <h5 className="h5">Selected products</h5>
            </button>
          </div>
          <button
            type="button"
            className="bg-gray-100 rounded-xl text-green hover:bg-gray-900 P-3 hover:text-white"
            onClick={toggleCartMessage}
          >
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 16 16"
              className="text-inherit"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
              ></path>
            </svg>
          </button>
        </div>
        {showCartMessage && (
          <div className="bg-white shadow-lg rounded-2xl col-span-full group overflow-x-auto">
            <div className="group-hover:bg-[#FAFAFA] p-3 rounded-2xl p-0 group-hover:!bg-white">
              {isLoading && <Loader />}
              {/* {successMessage && (
                <div className="flex justify-center w-full p-4 bg-green-100 text-green-700 mb-4 rounded">
                  {successMessage}
                </div>
              )} */}
              {cartItems.length === 0 ? (
                <div className="w-full h-fit py-10">
                  <h5 className="text-center text-xs">No items in cart</h5>
                </div>
              ) : (
                <div className="bg-white shadow-default rounded-xl col-span-full group">
                  {cartItems.map((item) => (
                    <div
                      key={item.id}
                      className="lg:grid lg:grid-cols-7 min-w-max border border-gray rounded-2xl cardInner hover:bg-[#FAFAFA] mb-4"
                    >
                      <div className="lg:col-span-2 flex items-center gap-2 h-16">
                        <a className="px-2 bg-white rounded-[20px]" href="#">
                          {item.image ? (
                            <img
                              className="w-12 h-12 object-cover"
                              src={`${BASE_IMAGE_URL}${item.image}`}
                              alt={item.name}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = noimage;
                              }}
                            />
                          ) : (
                            <img
                              className="w-12 h-12 object-cover mr-4"
                              src={noimage}
                              alt=""
                            />
                          )}
                        </a>
                        <a href="">
                          <h5>{item.brand || "Brand Name"}</h5>
                          <p className="text-black text-xs truncate lg:w-64 w-48">
                            {item.name}
                          </p>
                        </a>
                      </div>
                      <div className="col-span-2 flex items-center lg:justify-around text-center lg:w-auto w-48">
                        <div className="flex items-center gap-2 lg:hidden">
                          <div className="lg:w-1 w-20 lg:ml-0 h-3 bg-success rounded-full"></div>
                        </div>
                        <div>
                          <p className="text-black hidden lg:block text-xs">
                            Product Code
                          </p>
                          <p className="text-black mr-3 text-gray-500 text-xs">
                            {item.id}
                          </p>
                        </div>
                        <div className="lg:w-20">
                          <p className="text-black hidden lg:block text-xs">
                            Barcode
                          </p>
                          <p className="text-black lg:bg-transparent bg-gray-200 rounded-3xl px-2 text-xs">
                            {item.barcode}
                          </p>
                        </div>
                      </div>
                      <div className="col-span-3 flex justify-around items-center mt-2 mb-2 lg:w-auto w-48">
                        <div>
                          <h4 className="font-semibold ml-2 lg:ml-0 mr-2 text-xs">
                            ${item.price}
                          </h4>
                        </div>
                        <div className="flex">
                          {/* <button
                            className="bg-gray-300 hover:bg-gray-400 p-1 rounded-md text-xs mb-1 w-auto mx-1 min-w-[60px]"
                            onClick={() => handleSetHalfBoxQuantity(item.id)}
                          >
                            Half Box
                          </button> */}
                          <div className="flex items-center mr-2">
                            <div className="md:w-24 w-20 lg:h-10 h-6 border border-gray-light flex flex-flow rounded-[17px]">
                              <button
                                className="w-8 flex items-center justify-center"
                                onClick={() => handleDecreaseQuantity(item.id)}
                                disabled={item.quantity <= 0}
                              >
                                -
                              </button>
                              <input
                                className="w-10 outline-none text-center bg-transparent text-xs"
                                type="number"
                                value={
                                  item.quantityType === "box"
                                    ? Math.max(
                                        1,
                                        Math.ceil(item.quantity / BOX_SIZE)
                                      )
                                    : Math.max(3, item.quantity)
                                }
                                readOnly
                              />
                              <button
                                className="w-8 flex items-center justify-center "
                                onClick={() => handleIncreaseQuantity(item.id)}
                              >
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col lg:flex-row items-center mr-2">
                          <div className="flex flex-col lg:flex-row items-start lg:mb-0 mb-1">
                            <label className="flex items-center mr-2 mb-1 custom-radio">
                              <input
                                type="radio"
                                name={`quantityType-${item.id}`}
                                value="piece"
                                checked={item.quantityType === "piece"}
                                onChange={() =>
                                  handleSetQuantityType(item.id, "piece")
                                }
                              />
                              <span className="ml-2 text-xs">Piece</span>
                            </label>
                            <label className="flex items-center custom-radio">
                              <input
                                type="radio"
                                name={`quantityType-${item.id}`}
                                value="box"
                                checked={item.quantityType === "box"}
                                onChange={() =>
                                  handleSetQuantityType(item.id, "box")
                                }
                              />
                              <span className="ml-2 text-xs">Box</span>
                            </label>
                          </div>
                        </div>
                        <div className="flex flex-col items-center mr-2">
                          <button
                            className="bg-gray-200 p-1 rounded-md text-xs min-w-[60px]"
                            onClick={() => handleClearQuantity(item.id)}
                          >
                            Clear
                          </button>
                        </div>
                        <button
                          className="text-red-600 hover:text-red-900 text-xs"
                          onClick={() => handleRemoveItem(item.id)}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  ))}
                  <div className="flex justify-between gap-2 md:gap-4 mx-4 md:mx-14 mb-3 bg-gray-200 p-3 rounded-2xl px-5">
                    <div className="flex items-center">
                      <h5 className="font-semibold mr-3 text-xs">SubTotal:</h5>
                      <h5 className="font-semibold text-sm">
                        ${subtotal.toFixed(2)}
                      </h5>
                    </div>
                    <div className="flex items-center">
                      <h4 className="font-semibold mr-3 text-xs">Total:</h4>
                      <h4 className="font-semibold text-sm">
                        ${subtotal.toFixed(2)}
                      </h4>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {/* Notes Section */}
        {cartItems.length > 0 && (
          <div className="p-2 border border-gray-300 rounded-lg shadow-md bg-white mt-5">
            <h2 className="text-sm mb-2 text-center">Additional Note</h2>
            <textarea
              className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 bg-white"
              placeholder="Write your notes here..."
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              rows={5}
            ></textarea>
          </div>
        )}
        {cartItems.length > 0 && (
          <button
            className="mb-5 bg-gray-200  pr-2 flex gap-2 items-center justify-center mx-auto mt-5 w-60 h-11 rounded-2xl text-gray-800 hover:bg-gray-800 hover:text-white"
            onClick={handleCheckout}
          >
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 16 16"
              className="text-inherit"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5v-.5zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0zM8.5 8a.5.5 0 0 0-1 0v1.5H6a.5.5 0 0 0 0 1h1.5V12a.5.5 0 0 0 1 0v-1.5H10a.5.5 0 0 0 0-1H8.5V8z"
              ></path>
            </svg>
            <h5 className="font-sans text-xs text-inherit">Submit Order</h5>
          </button>
        )}

        {/* Delivery Partners */}
        <div className="bg-white-500 shadow-lg rounded-2xl mt-5 col-span-full group mb-20 lg:mb-5">
          <div className="lg:p-9 border border-gray-light p-3 group-hover:bg-[#FAFAFA] rounded-2xl group-hover:!bg-white">
            <div className="flex flex-col md:flex-row lg:gap-4 gap-2 mb-10 ml-5">
              <div className="flex lg:gap-4 gap-2 items-center whitespace-nowrap">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 16 16"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"></path>
                </svg>
                <h5 className="text-xs">Secured payment</h5>
              </div>
              <div className="flex flex-wrap gap-4 justify-center md:justify-start md:ml-12">
                <img
                  className="w-auto h-6 object-cover"
                  src={mastercard}
                  alt=""
                />
                <img className="w-auto h-6 object-cover" src={visa} alt="" />
                <img
                  className="w-auto h-6 object-cover"
                  src={american_express}
                  alt=""
                />
                <img className="w-auto h-6 object-cover" src={ideal} alt="" />
                <img className="w-auto h-6 object-cover" src={maestro} alt="" />
                <img className="w-auto h-6 object-cover" src={bank} alt="" />
                {/* <img className="w-auto h-6 object-cover" src={klarna} alt="" /> */}
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-2 mt-2 ml-5">
              <div className="flex gap-4 items-center whitespace-nowrap">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 16 16"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                </svg>
                <h5 className="text-xs">Shipment with trusted partners</h5>
              </div>
              <div className="flex flex-wrap gap-4 justify-center md:justify-start md:ml-12">
                <img className="w-auto h-6 object-cover" src={fedex} alt="" />
                <img className="w-auto h-6 object-cover" src={ups} alt="" />
                <img className="w-auto h-6 object-cover" src={postnl} alt="" />
                <img className="w-auto h-6 object-cover" src={dhl} alt="" />
              </div>
            </div>
          </div>
        </div>
        {!billingAddress && (
          <PaymentModal
            isModalOpen={isPaymentModalOpen}
            toggleModal={() => setPaymentModalOpen(false)}
            customerNumber={customerNumber}
          />
        )}
        {/* {!isLoggedIn && ( */}
        <Modal
          isModalOpen={isLoginModalOpen}
          toggleModal={() => setLoginModalOpen(false)}
          fromCartPage={true}
        />
        {/* )} */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cartItems: state.cardItems.cartItems || [],
  billingAddress: state.payment.billingAddress,
  isLoggedIn: state.auth.isLoggedIn,
  customerNumber: state.auth.user?.customerNumber || null,
  searchResults: state.searchReducer.searchResults,
  user: state.auth.user || { customerNumber: null },
});

export default connect(mapStateToProps, {
  removeFromCart,
  increaseQuantity,
  decreaseQuantity,
  clearCart,
  setHalfBoxQuantity,
  setFullBoxQuantity,
  resetQuantity,
  setQuantityType,
})(Cart);
