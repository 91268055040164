import * as constants from "../constants";

const initialState = {
  isLoggedIn: false,
  user: null,
  error: null,
  // companyName: "",
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload.customer,
        // companyName: action.payload.companyName,
        error: null,
      };
    case constants.LOGIN_FAILURE:
      return {
        ...state,
        user: null,
        error: action.payload,
      };
    case constants.LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        companyName: "",
      };
    case constants.STORE_USER_DATA:
      return {
        ...state,
        user: {
          ...state.user,
          customerNumber: action.payload.customerNumber,
          phoneNumber: action.payload.phoneNumber,
        },
      };
    default:
      return state;
  }
};

export default authReducer;