import React from 'react'
import CateSidebar from '../../Components/CategoriesSidebar/CateSidebar'

const Categories = ({searchQuery, handleSearch, handleChange}) => {
  return (
    <div>
      <CateSidebar searchQuery={searchQuery} handleSearch={handleSearch} handleChange={handleChange}/>
    </div>
  )
}
export default Categories