import React from "react";

const PWAModal = ({ show, handlePwaModal, handleInstallApp }) => {
    return (
        <>
          {show && (
            <div className="fixed inset-0 z-50 overflow-y-auto">
              <div className="flex items-center justify-center min-h-screen">
                <div className="fixed inset-0 transition-opacity">
                  <div className="absolute inset-0 bg-gray-500 opacity-75" onClick={() => handlePwaModal(false)}></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div className="inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full">
                  <div className="bg-white p-4">
                    <div className="flex justify-center items-center gap-3">
                      <div>
                        <img
                          src="/logo.png"
                          alt="Joon Beauty"
                          className="h-24 w-24 object-contain rounded-full bg-gray-500"
                        />
                      </div>
                      <div>
                        <h5 className="font-semibold text-lg text-center text-black">
                          Install the JCOP App
                        </h5>
                        <div className="flex justify-center">
                          <button
                            className="btn m-1 px-6 py-2 rounded-md text-white bg-gray-500"
                            onClick={handleInstallApp}
                          >
                            Install
                          </button>
                          <button
                            className="btn m-1 px-6 py-2 rounded-md text-gray-800 bg-gray-200"
                            onClick={() => handlePwaModal(false)}
                          >
                            Not Now
                          </button>
                        </div>
                        <p className="text-center text-black mb-0">
                          Joon Beauty - Order Portal
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      );
    };

export default PWAModal;