import * as constants from "../constants";

const storedBillingAddress = localStorage.getItem('billingAddress');
// console.log('Stored billing address:', storedBillingAddress);

let billingAddress;
try {
  billingAddress = storedBillingAddress ? JSON.parse(storedBillingAddress) : {};
} catch (error) {
  console.error('Error parsing billing address:', error);
  billingAddress = {};
}

const initialState = {
  isLoading: false,
  error: null,
  billingAddress: billingAddress,
  paymentDetailsAdded: false, //May be removed after
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.REQUEST_PAYMENT:
      return {
        ...state,
        isLoading: true,
      };

      case constants.CLEAR_BILLING_ADDRESS:
      localStorage.removeItem('billingAddress');
      return {
        ...state,
        billingAddress: {}
      };
    case constants.RECEIVE_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case constants.RECEIVE_PAYMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case constants.UPDATE_BILLING_ADDRESS:
      localStorage.setItem('billingAddress', JSON.stringify(action.payload));
      return {
        ...state,
        billingAddress: action.payload,
      };
    case constants.SET_PAYMENT_DETAILS_ADDED://May be removed after
      return {
        ...state,
        paymentDetailsAdded: action.payload,
      };
    default:
      return state;
  }
};

export default paymentReducer;